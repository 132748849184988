import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 1104 148" {...props}>
			<mask
				id="mask0_1722_369967"
				style={{ maskType: "alpha" }}
				maskUnits="userSpaceOnUse"
				x="0"
				y="0"
				width="1104"
				height="148"
			>
				<rect width="1104" height="148" fill="#80BC00" />
			</mask>
			<g mask="url(#mask0_1722_369967)">
				<path
					d="M0 0H1104V116C1104 120.418 1100.42 124 1096 124H24C10.7452 124 0 113.255 0 100V0Z"
					fill={props.htmlColor}
				/>
				<path
					opacity="0.4"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M647.381 72.8283C646.548 72.4759 645.608 72.8732 645.28 73.7156L642.816 80.0536L636.834 77.5212C635.976 77.1579 635.007 77.5675 634.669 78.436C634.331 79.3046 634.753 80.3032 635.611 80.6665L641.593 83.1989L639.129 89.537C638.801 90.3794 639.211 91.348 640.043 91.7004C640.875 92.0528 641.816 91.6555 642.143 90.8131L644.607 84.4751L650.59 87.0076C651.448 87.3709 652.417 86.9613 652.755 86.0927C653.093 85.2242 652.671 84.2255 651.812 83.8622L645.83 81.3297L648.295 74.9917C648.622 74.1493 648.213 73.1807 647.381 72.8283Z"
					fill="black"
					fillOpacity="0.87"
				/>
				<path
					opacity="0.4"
					d="M381.875 3.5C381.875 6.87451 379.269 9.5 376.188 9.5C373.106 9.5 370.5 6.87451 370.5 3.5C370.5 0.125486 373.106 -2.5 376.188 -2.5C379.269 -2.5 381.875 0.125486 381.875 3.5Z"
					stroke="black"
					strokeOpacity="0.87"
					strokeWidth="3"
				/>
				<path
					opacity="0.4"
					d="M851.875 37.5C851.875 40.8745 849.269 43.5 846.188 43.5C843.106 43.5 840.5 40.8745 840.5 37.5C840.5 34.1255 843.106 31.5 846.188 31.5C849.269 31.5 851.875 34.1255 851.875 37.5Z"
					stroke="black"
					strokeOpacity="0.87"
					strokeWidth="3"
				/>
				<g opacity="0.2">
					<ellipse
						cx="1040.88"
						cy="1"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1040.88"
						cy="33"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1040.88"
						cy="17"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1040.88"
						cy="49"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1056.21"
						cy="1"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1056.21"
						cy="33"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1056.21"
						cy="17"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1056.21"
						cy="49"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1071.54"
						cy="1"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1071.54"
						cy="33"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1071.54"
						cy="17"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1071.54"
						cy="49"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1086.88"
						cy="1"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1086.88"
						cy="33"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1086.88"
						cy="17"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
					<ellipse
						cx="1086.88"
						cy="49"
						rx="2.875"
						ry="3"
						fill="black"
						fillOpacity="0.87"
					/>
				</g>
			</g>
		</SvgIcon>
	);
};

export default Icon;
