import React, { useContext, useState } from "react";

export interface LoadingContextType {
	setLoading: (loading: boolean) => void;
	loading: boolean;
}

const LoadingContext = React.createContext<LoadingContextType>({
	loading: false,
	setLoading: () => false,
});

export const LoadingProvider: React.FC = ({ children }) => {
	const [loading, setLoading] = useState(false);

	return (
		<LoadingContext.Provider
			value={{
				loading,
				setLoading,
			}}
		>
			{children}
		</LoadingContext.Provider>
	);
};

function useLoadingContext(): LoadingContextType {
	const { ...data } = useContext(LoadingContext);

	return data;
}

export default useLoadingContext;
