import { useAuthState } from "@saleor/sdk";
import React from "react";

import { notNullable } from "@/lib/util";
import {
	AddressDetailsFragment,
	AddressTypeEnum,
	CountryCode,
	CurrentUserAddressesDocument,
	useCreateAccountAddressMutation,
	useUpdateAccountAddressMutation,
} from "@/saleor/api";

import { AddressForm, AddressFormData } from "./AddressForm";

export type UserAddressFormProps = {
	address?: AddressDetailsFragment;
	variant?: "create" | "update";
	onCompleted?: () => void;
	addressTypes?: AddressTypeEnum[];
};

const UserAddressForm: React.VFC<UserAddressFormProps> = ({
	onCompleted,
	addressTypes,
	address,
	variant = "create",
}) => {
	const { user } = useAuthState();
	const [createAccountAddress] = useCreateAccountAddressMutation({});
	const [updateAccountAddress] = useUpdateAccountAddressMutation({});

	const existingData = React.useMemo(() => {
		if (variant === "update") {
			return address;
		}
		if (user) {
			return {
				id: "",
				firstName: user.firstName,
				lastName: user.lastName,
				streetAddress1: "",
				streetAddress2: "",
				city: "",
				country: {
					country: "Singapore",
					code: CountryCode.Sg,
				},
				postalCode: "",
				unitNumber: "",
			};
		}
		return undefined;
	}, [address, user, variant]);

	const performCreateAddress = async (formData: AddressFormData) => {
		if (addressTypes && addressTypes.length > 0) {
			const results = await Promise.all(
				addressTypes.map((type) => {
					return createAccountAddress({
						variables: {
							address: {
								...formData,
							},
							type,
						},
						refetchQueries: [
							{
								query: CurrentUserAddressesDocument,
								variables: {},
							},
						],
						awaitRefetchQueries: true,
					});
				})
			);
			const last = results[results.length - 1];
			return last.data?.accountAddressCreate?.errors.filter(notNullable) || [];
		}
		const { data } = await createAccountAddress({
			variables: {
				address: {
					...formData,
				},
			},
			refetchQueries: [
				{
					query: CurrentUserAddressesDocument,
					variables: {},
				},
			],
			awaitRefetchQueries: true,
		});
		return data?.accountAddressCreate?.errors.filter(notNullable) || [];
	};

	const performMutation = async (formData: AddressFormData) => {
		if (variant === "update") {
			if (!address) {
				return [];
			}
			const { data } = await updateAccountAddress({
				variables: { id: address.id, address: formData },
			});
			return data?.accountAddressUpdate?.errors.filter(notNullable) || [];
		}
		return performCreateAddress(formData);
	};

	const handleFinish = () => {
		onCompleted && onCompleted();
	};

	return (
		<AddressForm
			toggleEdit={handleFinish}
			updateAddressMutation={performMutation}
			existingAddressData={existingData}
		/>
	);
};

export default UserAddressForm;
