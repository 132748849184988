import React from "react";

import { MenuItemFragment, ProductFilterInput } from "@/saleor/api";

import { RichText } from ".";
import CollectionBlock, { CollectionIcon } from "./product/CollectionBlock";

export interface HomepageBlockProps {
	menuItem: MenuItemFragment;
}

export const HomepageBlock = ({ menuItem }: HomepageBlockProps) => {
	const filter: ProductFilterInput = {};
	if (!!menuItem.page?.id) {
		return (
			<div className="pb-10">
				{!!menuItem.page?.content && <RichText jsonStringData={menuItem.page?.content} />}
			</div>
		);
	}
	let link = "";
	if (!!menuItem.category?.id) {
		filter.categories = [menuItem.category?.id];
		link = `/category/${menuItem.category?.slug}`;
	}
	if (!!menuItem.collection?.id) {
		filter.collections = [menuItem.collection?.id];
		link = `/collection/${menuItem.collection?.slug}`;
	}
	const slug = menuItem.category?.slug || menuItem.collection?.slug || menuItem.page?.slug;

	return (
		<CollectionBlock
			name={menuItem.name}
			iconName={slug as CollectionIcon}
			link={link}
			filter={filter}
		/>
	);
};

export default HomepageBlock;
