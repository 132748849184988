import { Trans } from "@lingui/macro";
import { Box, Typography } from "@mui/material";
import { Dictionary } from "@reduxjs/toolkit";
import React from "react";

// import { RichText } from "./RichText";
import HeroImage from "@/icons/HeroImage";
import { CategoryDetailsFragment, CollectionDetailsFragment, MerchantFragment } from "@/saleor/api";

type Entity = CollectionDetailsFragment | CategoryDetailsFragment | MerchantFragment;

export interface PageHeroProps {
	entity: Entity;
}

const extractColor = (entity: Entity) => {
	const metadata = (entity as CategoryDetailsFragment).metadata;
	const defaultValues = {
		"bg-color": "#C6B784",
		"text-color": "#FFFFFF",
	};
	if (metadata) {
		return metadata.reduce<Dictionary<string>>((a, c) => {
			if (c) {
				a[c.key] = c?.value;
			}
			return a;
		}, defaultValues);
	}
	return defaultValues;
};

export const PageHero = ({ entity }: PageHeroProps) => {
	const renderIcon = () => {
		let icon = (entity as CategoryDetailsFragment).icon;
		if (!icon) {
			icon = (entity as CollectionDetailsFragment).backgroundImage;
		}
		if (icon) {
			return (
				<div
					className="absolute top-2 left-1/2 h-full w-32 md:w-64"
					style={{
						backgroundImage: `url(${icon.url})`,
						backgroundRepeat: "no-repeat",
						backgroundSize: "contain",
						transform: "translateX(-50%)",
					}}
				/>
			);
		}
		return null;
	};

	const meta = extractColor(entity);
	return (
		<div className="container mx-auto rounded-md flex items-center relative w-full aspect-[7.45/1] min-h-fit">
			<HeroImage
				style={{
					position: "absolute",
					top: 0,
					left: 0,
					right: 0,
					bottom: 0,
					width: "100%",
					height: "100%",
				}}
				htmlColor={meta["bg-color"]}
			/>
			<div className="ml-2 sm:ml-16 text-gray-50 text-center sm:text-left pt-4 sm:pt-2 z-20">
				<h1
					className="text-xl sm:text-2xl md:text-4xl font-extrabold mb-8 uppercase"
					style={{ maxWidth: 300, color: meta["text-color"] }}
				>
					{entity.name}
				</h1>
			</div>
			{renderIcon()}
			<Box
				sx={{ display: { xs: "none", lg: "flex" } }}
				className="absolute center-centered right-0 h-full w-100 mr-2"
			>
				{entity.slug === "mooncake" && (
					<div className="flex flex-col justify-center">
						<h6 className="text-lg text-red-400 sm:text-2xl md:text-base font-extrabold mb-0 uppercase">
							{"NO MINIMUM ORDER"}
						</h6>

						<h6 className="text-lg text-red-400 sm:text-2xl md:text-base font-extrabold mb-0 uppercase">
							{"FLAT DELIVERY FEE TO YOUR CLIENT'S DOORSTEP"}
						</h6>
					</div>
				)}
			</Box>
		</div>
	);
};

export default PageHero;
