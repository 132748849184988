import { t } from "@lingui/macro";
import { NextSeo } from "next-seo";

interface BaseSeoProps {
	title?: string;
	description?: string;
}

export const BaseSeo = ({ title, description }: BaseSeoProps) => {
	const baseTitle = t`GM Rewards by GoalsMapper`;
	const baseDescription = t`Effortless rewards selection for your clients`;

	const seoTitle = title || baseTitle;
	const seoDescription = description || baseDescription;

	return (
		<NextSeo
			title={seoTitle}
			description={seoDescription}
			openGraph={{
				title: seoTitle,
				description: seoDescription,
				images: [
					{
						url: `${process.env.NEXT_PUBLIC_APP_URL}/images/seo-banner.png`,
						alt: t`GoalsMapper Rewards hero image`,
					},
				],
				site_name: t`GM Rewards by GoalsMapper`,
			}}
		/>
	);
};

export default BaseSeo;
