import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 40 41" {...props}>
			<path d="M13.3306 19.6523H0V39.9982H13.3306V19.6523Z" fill="#CE412D" />
			<path d="M26.6607 1.45703H13.3301V40.0083H26.6607V1.45703Z" fill="#31978C" />
			<path d="M40.0005 13.5352H26.6699V40.0091H40.0005V13.5352Z" fill="#DC8744" />
			<path d="M13.3306 18.1992H0V38.545H13.3306V18.1992Z" fill="#E56353" />
			<path d="M26.6607 0H13.3301V38.5513H26.6607V0Z" fill="#44C4A1" />
			<path d="M40.0005 12.0781H26.6699V38.5521H40.0005V12.0781Z" fill="#FCD462" />
			<path
				d="M18.2637 14.9922H21.7306V23.5618H19.8913V16.6199H18.2637V14.9922Z"
				fill="white"
			/>
			<path
				d="M33.9858 24.4656C34.2056 24.1564 34.3114 23.8634 34.3114 23.5786C34.3114 23.2937 34.2137 23.0496 34.0265 22.8624C33.8393 22.6671 33.5952 22.5775 33.3022 22.5775C32.7651 22.5775 32.2524 22.96 31.7722 23.7332L30.2422 22.8217C30.641 22.2032 31.0804 21.7311 31.5606 21.4138C32.0408 21.0964 32.6511 20.9336 33.3917 20.9336C34.1323 20.9336 34.7834 21.1696 35.3449 21.6416C35.9065 22.1137 36.1832 22.7484 36.1832 23.5623C36.1832 24.0017 36.0692 24.4249 35.8495 24.8319C35.6216 25.2388 35.2066 25.7515 34.6043 26.3863L33.0581 27.9895H36.4436V29.7067H30.3968V28.2825L32.9116 25.7108C33.408 25.1818 33.7661 24.7749 33.9858 24.4656Z"
				fill="white"
			/>
			<path
				d="M4.07749 25.6706V24.043H9.32672V25.3695L7.57697 27.3715C8.26059 27.4855 8.79772 27.7703 9.17209 28.2342C9.54645 28.6981 9.73363 29.2271 9.73363 29.8375C9.73363 30.7327 9.43251 31.4407 8.82214 31.9534C8.21176 32.4661 7.43048 32.7184 6.48644 32.7184C5.53425 32.7184 4.57393 32.3848 3.60547 31.7174L4.39489 30.1955C5.20058 30.7652 5.92489 31.0501 6.56782 31.0501C6.95032 31.0501 7.26772 30.9524 7.52001 30.7652C7.77229 30.578 7.89437 30.3013 7.89437 29.9514C7.89437 29.5933 7.74788 29.3166 7.46304 29.105C7.1782 28.8934 6.77942 28.7958 6.27484 28.7958C6.00628 28.7958 5.62377 28.8771 5.13547 29.0318V27.6238L6.77942 25.6869L4.07749 25.6706Z"
				fill="white"
			/>
		</SvgIcon>
	);
};

export default Icon;
