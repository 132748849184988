import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 40 40" {...props}>
			<path
				d="M18.3475 1.2567C18.8676 -0.418901 21.1323 -0.418901 21.6524 1.2567L25.1826 12.6294C25.4152 13.3788 26.0823 13.8861 26.8351 13.8861H38.2591C39.9422 13.8861 40.642 16.1406 39.2803 17.1762L30.0381 24.2049C29.4292 24.6681 29.1744 25.489 29.407 26.2383L32.9372 37.611C33.4573 39.2866 31.6251 40.68 30.2634 39.6444L21.0212 32.6157C20.4123 32.1526 19.5876 32.1526 18.9787 32.6157L9.73647 39.6444C8.37477 40.68 6.54261 39.2866 7.06273 37.611L10.5929 26.2383C10.8255 25.489 10.5707 24.6681 9.96175 24.2049L0.719554 17.1762C-0.642149 16.1406 0.057674 13.8861 1.74083 13.8861H13.1648C13.9175 13.8861 14.5847 13.3788 14.8173 12.6294L18.3475 1.2567Z"
				fill="#FFA400"
			/>
		</SvgIcon>
	);
};

export default Icon;
