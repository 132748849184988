import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Image from "next/image";
import { useRef } from "react";
import Slider from "react-slick";

import { cloudfrontImageLoader } from "@/lib/util";
import { ProductMediaFragment } from "@/saleor/api";

import styles from "./index.module.sass";

type ProductMediaCarouselProps = {
	media?: ProductMediaFragment[];
	onMediaClick: (media: ProductMediaFragment) => void;
};

const ProductMediaCarousel = ({ media, onMediaClick }: ProductMediaCarouselProps) => {
	const mainSlider = useRef<Slider>(null);
	const navSlider = useRef<Slider>(null);

	const renderMediaContent = (media: ProductMediaFragment, priority = false) => {
		return (
			<>
				{media.type === "IMAGE" && (
					// <Image
					//     priority={priority}
					//     src={media.url}
					//     alt={media.alt}
					//     layout="fill"
					//     objectFit="cover"
					// />
					<Image
						loader={cloudfrontImageLoader}
						src={media.url}
						alt={media.alt}
						layout="fill"
						priority={priority}
					/>
				)}
				{/* {media.type === "VIDEO" && (
                            <div
                                onClick={() => {
                                    setVideoToPlay(media);
                                }}
                            >
                                <Image
                                    src={
                                        "https://img.youtube.com/vi/" +
                                        getYouTubeIDFromURL(media.url) +
                                        "/maxresdefault.jpg"
                                    }
                                    alt={media.alt}
                                    layout="fill"
                                    objectFit="cover"
                                />
                                <div
                                    className={
                                        "transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-110 absolute w-full h-full flex justify-center items-center bg-transparent"
                                    }
                                >
                                    <PlayIcon className="h-12 w-12" />
                                </div>
                            </div>
                        )} */}
			</>
		);
	};

	const renderMediaItem = (media: ProductMediaFragment, index: number) => {
		return (
			<div
				key={media.url}
				className={"aspect-w-1 aspect-h-1"}
				onClick={() => onMediaClick(media)}
			>
				{renderMediaContent(media, index === 0)}
			</div>
		);
	};

	const renderMediaNavItem = (media: ProductMediaFragment) => {
		return (
			<div key={media.url} className={"aspect-w-1 aspect-h-1"}>
				{renderMediaContent(media)}
			</div>
		);
	};
	const mediaCount = media?.length || 0;
	return (
		<div className="w-full">
			<Slider
				ref={mainSlider}
				className={styles.slider}
				slidesToShow={1}
				slidesToScroll={1}
				rows={1}
			>
				{media?.map((media: ProductMediaFragment, index) => {
					return renderMediaItem(media, index);
				})}
			</Slider>
			{mediaCount > 1 && (
				<div className="px-8 pt-4">
					<Slider
						className={styles.navSlider}
						asNavFor={mainSlider.current as Slider}
						ref={navSlider}
						slidesToShow={Math.min(5, mediaCount)}
						swipeToSlide
						focusOnSelect
						rows={1}
						infinite
						nextArrow={<ArrowForwardIosIcon />}
						prevArrow={<ArrowBackIosNewIcon />}
					>
						{media?.map((media: ProductMediaFragment) => {
							return renderMediaNavItem(media);
						})}
					</Slider>
				</div>
			)}
		</div>
	);
};

export { ProductMediaCarousel };
