import { Plural, t, Trans } from "@lingui/macro";
import { Box, Button, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import pick from "lodash/pick";
import React, { useState } from "react";

import { ProductFilterInput, ProductOrder, useProductCollectionQuery } from "@/saleor/api";

import CategoriesSideBar from "./CategoriesSideBar";
import { ProductCard } from "./ProductCard";
import BaseSeo from "./seo/BaseSeo";
import useChannelContext from "./shared/contexts/ChannelContext";
import { PRODUCT_ORDER_OPTIONS, ProductOrderOption, SortByDropdown } from "./shared/SortByDropdown";
import { Spinner } from "./Spinner";

export interface ProductCollectionProps {
	filter?: ProductFilterInput;
	title?: string;
	variant?: "block" | "collection";
	className?: string;
	count?: number;
}

export const ProductCollection: React.VFC<ProductCollectionProps> = ({
	filter,
	variant = "collection",
	title,
	className,
	count,
}) => {
	const theme = useTheme();
	const { channel } = useChannelContext();
	const isTabletUp = useMediaQuery(theme.breakpoints.up("sm"));
	const [sortOption, setSortOption] = useState<ProductOrderOption>(PRODUCT_ORDER_OPTIONS[0]);

	const { loading, error, data, fetchMore } = useProductCollectionQuery({
		variables: {
			filter,
			sortBy: pick(sortOption, ["direction", "field"]) as ProductOrder,
			first: count ?? 12,
			channel,
		},
		fetchPolicy: "cache-and-network",
	});

	const onSortOrderChange = (sortOrder: ProductOrderOption) => {
		setSortOption(sortOrder);
		fetchMore({
			variables: {
				sortBy: pick(sortOrder, ["direction", "field"]) as ProductOrder,
				after: null,
			},
		});
	};

	const onLoadMore = () => {
		fetchMore({
			variables: {
				after: data?.products?.pageInfo.endCursor,
			},
		});
	};

	const products = data?.products?.edges.map((edge) => edge.node) || [];

	const total = data?.products?.totalCount || 0;

	const renderProducts = () => {
		if (loading) return <Spinner />;
		if (error) return <p>Error</p>;

		if (products.length === 0) {
			return (
				<p>
					<Trans>No products.</Trans>
				</p>
			);
		}
		const displayProducts = variant === "block" ? products.slice(0, 4) : products;
		return (
			<>
				<ul
					role="list"
					className={clsx("grid grid-cols-1 sm:grid-cols-2 gap-6", {
						"lg:grid-cols-3": variant === "collection",
						"lg:grid-cols-4": variant === "block",
					})}
				>
					{displayProducts.map((product) => (
						<ProductCard key={product.id} product={product} />
					))}
				</ul>
				{variant === "collection" && products.length > 0 && (
					<Stack mt={4} justifyContent="center" alignItems="center" spacing={1}>
						<div className="text-sm text-gray-700">
							<Trans>
								Showing {products.length} of {total}
							</Trans>
						</div>
						<Button
							disabled={products.length === total}
							onClick={onLoadMore}
							variant="contained"
							className="mb-4"
						>
							<Trans>Load more</Trans>
						</Button>
					</Stack>
				)}
			</>
		);
	};

	const renderListHeader = () => {
		return (
			<Stack
				direction={{ xs: "column", sm: "row" }}
				justifyContent="space-between"
				alignItems="center"
				mb={2}
				flex={1}
				flexGrow={1}
			>
				<Stack flex={1} alignSelf={{ xs: "flex-start", sm: "center" }}>
					<div className="uppercase text-sm font-semibold text-gray-600">{title}</div>
					<div className="text-sm text-gray-500">
						<Plural one="# item" other="# items" value={total} />
					</div>
				</Stack>
				<Stack
					direction="row"
					alignItems="center"
					justifyContent={{ xs: "flex-end", sm: "flex-end" }}
					flex={1}
					flexGrow={1}
					alignSelf={{ xs: "flex-end", sm: "flex-end" }}
				>
					<div className="text-sm">
						<Trans>Sort By</Trans>
					</div>
					<SortByDropdown
						onOrderChange={onSortOrderChange}
						options={PRODUCT_ORDER_OPTIONS}
						value={sortOption}
					/>
				</Stack>
			</Stack>
		);
	};

	return (
		<Box display="flex" flexDirection="row" className={className}>
			<BaseSeo title={t`All Products - GoalsMapper Rewards`} />
			{isTabletUp && variant !== "block" && <CategoriesSideBar />}
			<div className="w-full">
				{variant !== "block" && renderListHeader()}
				{renderProducts()}
			</div>
		</Box>
	);
};

export default ProductCollection;
