import { Trans } from "@lingui/macro";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Breakpoint, Stack } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { styled } from "@mui/material/styles";
import React from "react";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

export interface DialogTitleProps {
	id: string;
	children?: React.ReactNode;
	onClose: () => void;
	icon?: React.ReactNode;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
	const { children, onClose, icon, ...other } = props;

	return (
		<DialogTitle sx={{ m: 0, p: 2 }} {...other}>
			<Box display="flex" flexDirection="row">
				<Stack direction="row" spacing={2}>
					{icon && icon}
					{children}
					{onClose ? (
						<Button
							aria-label="close"
							onClick={onClose}
							sx={{
								position: "absolute",
								right: 8,
								top: 8,
								color: (theme) => theme.palette.grey[500],
							}}
							startIcon={<CloseIcon />}
						>
							<Trans>Close</Trans>
						</Button>
					) : null}
				</Stack>
			</Box>
		</DialogTitle>
	);
};

export interface GenericDialogProps {
	children?: React.ReactNode;
	onClose: () => void;
	isOpen: boolean;
	title?: string;
	renderActions?: () => React.ReactNode;
	maxWidth?: Breakpoint | false;
	titleIcon?: React.ReactNode;
	fullWidth?: boolean | false;
	disableEscapeKeyDown?: boolean | false;
}

const GenericDialog = ({
	isOpen,
	onClose,
	children,
	title,
	renderActions,
	maxWidth,
	titleIcon,
	fullWidth,
	disableEscapeKeyDown,
}: GenericDialogProps) => {
	return (
		<BootstrapDialog
			onClose={onClose}
			open={isOpen}
			maxWidth={maxWidth}
			fullWidth={fullWidth}
			disableEscapeKeyDown={disableEscapeKeyDown}
		>
			<BootstrapDialogTitle id="customized-dialog-title" onClose={onClose} icon={titleIcon}>
				<div className="text-base font-semibold">{title}</div>
			</BootstrapDialogTitle>
			<DialogContent className="flex" dividers>
				{children}
			</DialogContent>
			{renderActions && <DialogActions>{renderActions()}</DialogActions>}
		</BootstrapDialog>
	);
};

export default GenericDialog;
