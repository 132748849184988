import { t, Trans } from "@lingui/macro";
import { useAuthState } from "@saleor/sdk";
import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import Script from "next/script";
import { useEffect } from "react";
import { hotjar } from "react-hotjar";

import { Navbar } from ".";

export interface LayoutProps {
	children?: React.ReactNode;
}

const PAYMENT_METHODS = [
	{
		icon: "visa.svg",
		href: "https://www.visa.com",
		size: { width: 38, height: 13 },
	},
	{
		icon: "mastercard.svg",
		href: "https://www.mastercard.com",
		size: { width: 38, height: 15 },
	},
	{
		icon: "american-express.png",
		href: "https://www.americanexpress.com",
		size: { width: 38, height: 16 },
	},
	{
		icon: "jcb.svg",
		href: "https://www.global.jcb",
		size: { width: 38, height: 15 },
	},
	{
		icon: "dinners-club.svg",
		href: "https://www.dinersclub.com",
		size: { width: 38, height: 15 },
	},
	{
		icon: "union-pay.svg",
		href: "https://unionpay.com",
		size: { width: 38, height: 15 },
	},
	// , {
	// 	icon: 'apple-pay.svg',
	// 	href: 'https://www.apple.com/sg/en/apple-pay/',
	// 	size: { width: 38, height: 14 }
	// }
];

const Footer = () => {
	const { user } = useAuthState();
	const router = useRouter();
	const locale = router.locale || router.defaultLocale!;

	const handleContactClick = () => {
		if (window.zE) {
			window.zE.setLocale && window.zE.setLocale(locale);
			if (user && window.zE.identify) {
				window.zE.identify({
					name: `${user.firstName} ${user.lastName}`,
					email: user.email,
					organization: "GMR",
				});
			}
			window.zE("webWidget", "open");
		} else {
			console.error("window.ZE not loaded");
		}
	};

	const FOOTER_MENUS = [
		{
			title: t`GM Rewards`,
			children: [
				// 	{
				// 	label: t`Track My Orders`,
				// 	href: '/orders'
				// },
				{
					label: t`GM Coins`,
					href: "https://support.goalsmapper.com/hc/en-us/sections/4607950035353-Rewards-Points",
					target: "_blank",
					// eslint-disable-next-line @typescript-eslint/no-empty-function
					onClick: () => {},
				},
				{
					label: t`Delivery & Returns`,
					href: "https://support.goalsmapper.com/hc/en-us/sections/4607920676505-Delivery-and-Returns",
					target: "_blank",
				},
				{
					label: t`About Us`,
					href: "/about",
				},
			],
		},
		{
			title: t`Policies`,
			children: [
				{
					label: t`Privacy Policy`,
					href: "/privacy-policy",
				},
				{
					label: t`Cookie Policy`,
					href: "/cookie-policy",
				},
				{
					label: t`Terms & Conditions`,
					href: "/terms-conditions",
				},
			],
		},
		{
			title: t`Help & Support`,
			children: [
				// 	{
				// 	label: t`Email Us`,
				// 	href: '/email-us'
				// },
				// {
				// 	label: t`Sell with Us`,
				// 	href: '/register-seller'
				// },
				{
					label: t`FAQ`,
					href: "https://support.goalsmapper.com/hc/en-us/categories/4415869828377-GM-Rewards",
					target: "_blank",
				},
				{
					label: t`Contact Us`,
					onClick: handleContactClick,
				},
			],
		},
	];

	return (
		<div className="bg-gray-900 text-white pt-20 pb-10 text-center sm:text-left">
			<div className="max-w-7xl mx-auto px-8 divide-y divide-gray-700">
				<div className="grid grid-cols-1 gap-10 sm:gap-0 sm:grid-cols-4 pb-10">
					{FOOTER_MENUS.map((group) => {
						return (
							<div key={group.title}>
								<div className="uppercase text-sm font-semibold mb-4">
									{group.title}
								</div>
								<Image
									src="/icons/colorStripe.svg"
									alt="cc"
									width={64}
									height={3}
								/>
								<div className="mt-10">
									{group.children.map((cat) => {
										if (cat.href) {
											return (
												<div key={cat.label} className="text-sm mt-4">
													<Link key={cat.label} href={cat.href} passHref>
														<a
															className="text-white"
															target={cat.target || "_self"}
														>
															{cat.label}
														</a>
													</Link>
												</div>
											);
										}
										return (
											<div
												key={cat.label}
												className="text-sm mt-4 cursor-pointer"
												onClick={cat.onClick}
											>
												{cat.label}
											</div>
										);
									})}
								</div>
							</div>
						);
					})}
					<div>
						<div className="uppercase text-sm font-semibold mb-4">{t`Payment Methods`}</div>
						<Image src="/icons/colorStripe.svg" alt="cc" width={64} height={3} />
						<div className="flex flex-wrap mt-10 justify-center sm:justify-start">
							{PAYMENT_METHODS.map((m) => {
								return (
									<div
										key={m.href}
										className="bg-white mr-2 flex justify-center items-center p-1 mb-2"
									>
										<a href={m.href}>
											<Image
												src={`/icons/payments/${m.icon}`}
												alt={m.icon}
												width={m.size.width}
												height={m.size.height}
											/>
										</a>
									</div>
								);
							})}
						</div>
					</div>
				</div>
				<div className="flex flex-col md:flex-row justify-between pt-2 text-sm">
					<div className="pt-2">
						<Trans>
							© Copyright {new Date().getFullYear()}. GM Rewards. All Rights Reserved.
						</Trans>
					</div>
					<div className="pt-2">
						<Trans>
							A Subsidiary of{" "}
							<span className="font-semibold">GoalsMapper Pte Ltd.</span>
						</Trans>
					</div>
				</div>
			</div>
		</div>
	);
};

const DynamicNavBar = dynamic<React.ComponentProps<typeof Navbar>>(
	() => import("./Navbar/index").then((navbar) => navbar.Navbar),
	{
		ssr: false,
	}
);

export const Layout = ({ children }: LayoutProps) => {
	useEffect(() => {
		if (
			process.env.NODE_ENV === "production" &&
			process.env.NEXT_PUBLIC_HJID &&
			process.env.NEXT_PUBLIC_HJSV
		) {
			hotjar.initialize(
				parseInt(process.env.NEXT_PUBLIC_HJID),
				parseInt(process.env.NEXT_PUBLIC_HJSV)
			);
		}
	}, []);

	return (
		<div className="min-h-screen flex flex-col">
			{process.env.NODE_ENV === "production" && (
				<>
					<Script
						strategy="afterInteractive"
						src={`https://www.googletagmanager.com/gtag/js?id=G-38CYWYS0VC`}
					/>
					<Script
						id="gtag-init"
						strategy="afterInteractive"
						dangerouslySetInnerHTML={{
							__html: `
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', 'G-38CYWYS0V', {
						page_path: window.location.pathname,
						});
          			`,
						}}
					/>
				</>
			)}
			<DynamicNavBar />
			<div
				className="align-middle flex flex-col flex-grow"
				style={{ backgroundColor: "rgba(0, 0, 0, 0.03)" }}
			>
				{children}
			</div>
			<Footer />
		</div>
	);
};

export default Layout;
