import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 25 24" {...props}>
			<path
				d="M9.33264 20.9867C8.94432 20.9867 8.63086 20.6726 8.63086 20.2836V17.2695C8.63086 16.8805 8.94432 16.5664 9.33264 16.5664C9.72096 16.5664 10.0344 16.8805 10.0344 17.2695V20.2836C10.0344 20.6726 9.72096 20.9867 9.33264 20.9867Z"
				fill="#4A2E1A"
			/>
			<path
				d="M15.3453 20.9867C14.957 20.9867 14.6436 20.6726 14.6436 20.2836V17.2695C14.6436 16.8805 14.957 16.5664 15.3453 16.5664C15.7337 16.5664 16.0471 16.8805 16.0471 17.2695V20.2836C16.0471 20.6726 15.7337 20.9867 15.3453 20.9867Z"
				fill="#2E2E2E"
			/>
			<path
				d="M15.3446 23.9984H9.33264C8.94432 23.9984 8.63086 23.6844 8.63086 23.2953V20.2812C8.63086 19.8922 8.94432 19.5781 9.33264 19.5781H15.3446C15.7329 19.5781 16.0464 19.8922 16.0464 20.2812V23.2953C16.0464 23.6844 15.7329 23.9984 15.3446 23.9984Z"
				fill="#FFE705"
			/>
			<path
				d="M16.047 23.2953V20.2812C16.047 19.8922 15.7335 19.5781 15.3452 19.5781H12.3369V23.9984H15.3452C15.7335 23.9984 16.047 23.6844 16.047 23.2953Z"
				fill="#FFCB00"
			/>
			<path
				d="M12.3413 0C7.8078 0 4.12109 3.69374 4.12109 8.23591C4.12109 12.2156 8.60784 17.5078 8.79966 17.7328C8.93534 17.8874 9.12716 17.9765 9.33301 17.9765H11.5881C12.0045 17.9765 12.3413 17.639 12.3413 17.2218V0V0Z"
				fill="#F32735"
			/>
			<path
				d="M12.3408 0C16.8744 0 20.5611 3.69374 20.5611 8.23591C20.5611 12.2156 16.0743 17.5078 15.8825 17.7328C15.7468 17.8874 15.555 17.9765 15.3491 17.9765H13.0941C12.6777 17.9765 12.3408 17.639 12.3408 17.2218V0Z"
				fill="#F32735"
			/>
			<path
				d="M12.341 0C10.0765 0 8.63086 3.84374 8.63086 8.23591C8.63086 12.1031 10.8485 17.3296 10.9421 17.55C11.0544 17.8078 11.307 17.9765 11.5877 17.9765H13.0895C13.3702 17.9765 13.6229 17.8078 13.7352 17.55C13.8287 17.3296 16.0464 12.1031 16.0464 8.23591C16.0464 3.84374 14.6054 0 12.341 0Z"
				fill="#F3F9F9"
			/>
			<path
				d="M13.0894 17.9765C13.3701 17.9765 13.6227 17.8078 13.735 17.55C13.8286 17.3296 16.0462 12.1031 16.0462 8.23591C16.0462 3.84374 14.6052 0 12.3408 0V17.9765H13.0894Z"
				fill="#E1ECEF"
			/>
			<path
				d="M16.0982 20.9844H8.57971C8.19139 20.9844 7.87793 20.6703 7.87793 20.2812C7.87793 19.8922 8.19139 19.5781 8.57971 19.5781H16.0982C16.4865 19.5781 16.8 19.8922 16.8 20.2812C16.8 20.6703 16.4865 20.9844 16.0982 20.9844Z"
				fill="#FFCB00"
			/>
			<path
				d="M16.7995 20.2812C16.7995 19.8922 16.486 19.5781 16.0977 19.5781H12.3408V20.9844H16.0977C16.486 20.9844 16.7995 20.6703 16.7995 20.2812Z"
				fill="#FFB454"
			/>
		</SvgIcon>
	);
};

export default Icon;
