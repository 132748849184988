import { Trans } from "@lingui/macro";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Slider from "@mui/material/Slider";
import { styled } from "@mui/material/styles";
import Image from "next/image";
import { useRouter } from "next/router";
import numbro from "numbro";
import React, { useReducer, useState } from "react";

import { useCategoryPathsQuery, useQuickFilterQuery } from "@/saleor/api";

import useChannelContext from "./shared/contexts/ChannelContext";

const PriceSlider = styled(Slider)({
	color: "#58E2C2",
	height: 8,
	padding: "16px 0 !important",
	"& .MuiSlider-track": {
		border: "none",
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		border: "8px solid #fff",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "none",
		},
	},
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 12,
		background: "unset",
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: "#52af77",
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
		"&:before": { display: "none" },
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
		},
		"& > *": {
			transform: "rotate(45deg)",
		},
	},
	"& .MuiSlider-rail": {
		opacity: 0.7,
		backgroundColor: "#D5D9DB",
	},
});

type State = {
	min: number;
	max: number;
};

type Action =
	| {
			type: "change";
			payload: State;
	  }
	| {
			type: "filterUpdated";
			payload: State;
	  };

const DEFAULT_RANGE = {
	min: 0,
	max: 500,
};

const initialState = DEFAULT_RANGE;

function reducer(state: State, action: Action) {
	switch (action.type) {
		case "change":
			return action.payload;
		case "filterUpdated":
			return action.payload;
		default:
			throw new Error();
	}
}
export const QuickFilter = () => {
	const router = useRouter();
	const { channel } = useChannelContext();
	const { data } = useCategoryPathsQuery({
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-and-network",
	});
	const [priceRange, dispatch] = useReducer(reducer, initialState);

	const [category, setCategory] = useState<string>();
	const [searchValue, setSearchValue] = useState("");

	const { data: productData } = useQuickFilterQuery({
		variables: {
			filter: { categories: category ? [category] : null },
			channel,
		},
	});

	const handleChange = (event: Event, newValue: number | number[], activeThumb: number) => {
		const val = newValue as number[];
		dispatch({
			type: "change",
			payload: {
				min: val[0],
				max: val[1],
			},
		});
	};

	const onSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
	};

	React.useEffect(() => {
		//console.log("changes", productData?.products);
		if (productData?.products) {
			dispatch({
				type: "filterUpdated",
				payload: {
					min: productData?.products.minPrice || DEFAULT_RANGE.min,
					max: productData?.products.maxPrice || DEFAULT_RANGE.max,
				},
			});
		}
	}, [productData?.products]);
	const handleMenuChange = (event: SelectChangeEvent) => {
		setCategory(event.target.value as string);
	};

	const onSearch = () => {
		router.push({
			pathname: "/search",
			query: {
				category,
				min: priceRange.min,
				max: priceRange.max,
				q: searchValue,
			},
		});
	};

	const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			onSearch();
		}
	};

	return (
		<div
			className="flex flex-col lg:flex-row rounded-2xl bg-gray-900 text-white py-8 px-8 bg-no-repeat"
			style={{ backgroundImage: 'url("/images/quick-search-bg.png")' }}
		>
			<Box flex={1} className="flex flex-col items-center justify-center">
				<div className="text-sm font-semibold mb-3">
					<Trans>Quick Search</Trans>
				</div>
				<Image src="/icons/colorStripe.svg" alt="cc" width={64} height={3} />
			</Box>
			{/* <Box flex={2} className='flex-1 mt-6 lg:ml-6 lg:mt-3'>
            <div className='flex flex-row items-center'>
                <div className='text-sm font-semibold'>
                    <Trans>Postal Code</Trans>
                </div>
                <Tooltip className='text-white' title="Add" placement="top">
                    <IconButton color='inherit'><InfoIcon color='inherit' /></IconButton>
                </Tooltip>
            </div>
            <WhiteTextField fullWidth variant='outlined' inputMode='decimal' />
        </Box> */}
			<Box
				flex={2}
				className="flex-1 mt-6 lg:ml-2 flex-col"
				sx={{ display: { xs: "none", md: "flex" } }}
			>
				<div className="text-sm font-semibold">
					<Trans>Gift Type</Trans>
				</div>
				<Select
					labelId="demo-simple-select-label"
					id="demo-simple-select"
					value={category}
					displayEmpty
					onChange={handleMenuChange}
					className="bg-white w-full text-gray-900 mt-2"
					style={{ height: 48 }}
					renderValue={(selected) => {
						if (selected === "") {
							return <Trans>All Categories</Trans>;
						}
						if (!selected || !data?.categories) {
							return <Trans>All Categories</Trans>;
						}
						return data?.categories?.edges.find(({ node }) => node.id === selected)
							?.node?.name;
					}}
				>
					<MenuItem value={""}>
						<Trans>All Categories</Trans>
					</MenuItem>
					{data?.categories?.edges.map(({ node }) => {
						return (
							<MenuItem key={node.id} value={node.id}>
								{node.name}
							</MenuItem>
						);
					})}
				</Select>
			</Box>
			<Box flex={2} className="flex flex-col mt-6 lg:ml-6">
				<div className="flex flex-row justify-between text-sm">
					<div>
						<Trans>From</Trans>
					</div>
					<div>
						<Trans>To</Trans>
					</div>
				</div>
				<div className="flex flex-row justify-between font-semibold mt-1">
					<div>
						{numbro(priceRange.min).formatCurrency({
							optionalMantissa: true,
							thousandSeparated: true,
						})}
					</div>
					<div>
						{numbro(priceRange.max).formatCurrency({
							optionalMantissa: true,
							thousandSeparated: true,
						})}
					</div>
				</div>
				<PriceSlider
					value={[priceRange.min, priceRange.max]}
					onChange={handleChange}
					valueLabelDisplay="auto"
					aria-labelledby="range-slider"
					min={productData?.products?.minPrice || DEFAULT_RANGE.min}
					max={productData?.products?.maxPrice || DEFAULT_RANGE.max}
					valueLabelFormat={(value: number) =>
						numbro(value).formatCurrency({
							optionalMantissa: true,
							thousandSeparated: true,
						})
					}
				/>
			</Box>
			<Box
				flex={4}
				className="flex-1 mt-6 lg:ml-6"
				sx={{ display: { xs: "flex", md: "none " } }}
			>
				<input
					value={searchValue}
					onChange={onSearchChange}
					onKeyDown={onKeyDown}
					type="text"
					className="bg-white w-full text-gray-900 mt-2"
					placeholder="Searching for..."
				/>
			</Box>
			<Box minWidth={125} className="flex flex-col mt-6 lg:mt-3 lg:ml-6 lg:mb-3 pt-10">
				<Button
					onClick={onSearch}
					variant="contained"
					fullWidth
					size="large"
					style={{ height: 48 }}
				>
					<Trans>Search Now</Trans>
				</Button>
			</Box>
		</div>
	);
};
