import { Trans } from "@lingui/macro";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useAuthState } from "@saleor/sdk";

import {
	ProductFavoriteDocument,
	useProductFavoriteQuery,
	useToggleProductFavoriteMutation,
} from "@/saleor/api";

export type ProductFavoriteButtonProps = {
	productId: string;
	variant: "default" | "compact";
};

export const ProductFavoriteButton: React.VFC<ProductFavoriteButtonProps> = ({
	productId,
	variant,
}) => {
	const theme = useTheme();
	const isMdUp = useMediaQuery(theme.breakpoints.up("md"));

	const { authenticated } = useAuthState();
	const { data } = useProductFavoriteQuery({
		variables: {
			id: productId,
		},
		skip: !authenticated || typeof window === "undefined",
	});
	const [toggleFavorite] = useToggleProductFavoriteMutation({
		refetchQueries: [
			{
				query: ProductFavoriteDocument,
				variables: { id: productId },
			},
		],
	});

	if (!authenticated) {
		return null;
	}

	const handleToggleFavorite = () => {
		toggleFavorite({
			variables: { id: productId },
		});
	};
	const isFavorite = data?.product?.favorite || false;

	if (variant === "compact") {
		return (
			<IconButton
				color={isFavorite ? "warning" : "info"}
				size="small"
				onClick={handleToggleFavorite}
			>
				<FavoriteIcon />
			</IconButton>
		);
	}

	if (isMdUp) {
		return (
			<Button onClick={handleToggleFavorite} size="small" className="capitalize">
				<Stack direction="column" alignItems="center" spacing={0.5}>
					<FavoriteIcon color={isFavorite ? "warning" : "info"} />
					{isFavorite ? (
						<Trans>Remove from Favorites</Trans>
					) : (
						<Trans>Add to Favorites</Trans>
					)}
				</Stack>
			</Button>
		);
	}
	return (
		<IconButton
			color={isFavorite ? "warning" : "info"}
			size="small"
			onClick={handleToggleFavorite}
		>
			<FavoriteIcon />
		</IconButton>
	);
};
