import { t, Trans } from "@lingui/macro";
import { Stack, Tooltip } from "@mui/material";
import Image from "next/image";
import Link from "next/link";
// import numbro from "numbro";
import React from "react";

import { Product, ProductCardFragment } from "@/saleor/api";

import OnSaleBadge from "./product/OnSaleBadge";
import PriceComponent from "./product/PriceComponent";

const styles = {
	grid: `grid grid-cols-4 gap-4`,
	product: {
		name: `block text-gray-900`,
		category: `block text-sm font-semibold text-brand-green uppercase`,
		details: `flex flex-col flex-1 px-4 py-2 border-gray-100 bg-gray-50 border-t divide-y divide-gray`,
	},
};

export interface ProductCardProps {
	product: ProductCardFragment;
	multipleCheckout?: boolean;
}

export const ProductCard: React.VFC<ProductCardProps> = ({ product, multipleCheckout = false }) => {
	const { thumbnail } = product;

	const imageStyle: React.CSSProperties = {};
	if (!!thumbnail?.url) {
		imageStyle.backgroundImage = `url(${thumbnail?.url})`;
		imageStyle.backgroundRepeat = "no-repeat";
		imageStyle.backgroundPosition = "center";
	}

	const renderOnSaleBadge = () => {
		return <OnSaleBadge productId={product.id} />;
	};

	const isPersonalisable = product.collections?.find((c) => c?.slug === "personalise");
	const isNewArrival = product.collections?.find((c) => c?.slug === "new-arrivals");
	const isSocialCause = product.collections?.find((c) => c?.slug === "social-enterprise");
	const isFreeDelivery = product.collections?.find((c) => c?.slug === "free-delivery");
	const isHalal = product.collections?.find((c) => c?.slug === "halal-muslim-friendly");
	const isNextDayDelivery = product.collections?.find((c) => c?.slug === "next-day-delivery");
	const isVegan = product.collections?.find((c) => c?.slug === "vegan");
	const isVegetarian = product.collections?.find((c) => c?.slug === "vegetarian");

	const renderFreeDelivery = () => {
		if (!isFreeDelivery) return false;
		return (
			<div className="flex text-yellow-500 font-semibold xs:text-[12px] sm:text-sm">
				<Trans>Free Delivery</Trans>
			</div>
		);
	};

	return (
		<li key={product.id} className="bg-white border rounded-lg overflow-hidden hover:shadow">
			<Link
				href={{
					pathname: multipleCheckout ? "/multiple/products/[slug]" : "/products/[slug]",
					query: { slug: product.slug },
				}}
				prefetch={false}
				passHref
			>
				<a className="h-full flex flex-col">
					<div className="flex flex-row sm:flex-col flex-1">
						<div className="w-2/5 sm:w-full">
							<div className="flex rounded flex-col h-full bg-gray-200 justify-between relative">
								<div
									className="bg-contain aspect-h-1 aspect-w-1 "
									style={imageStyle}
								/>

								<div className="flex flex-row justify-start px-2 mt-2 absolute inset-x-0 top-0">
									<Stack direction="row" spacing={1} alignItems="flex-end">
										{isNewArrival && (
											<div className="flex flex-row rounded-lg overflow-hidden">
												<div className="bg-red-500 text-white xs:px-1 xs:py-1 xs:text-[7px] sm:px-2 sm:py-3 font-semibold sm:text-xs m-auto uppercase">
													<Trans>New</Trans>
												</div>
											</div>
										)}
										{/* {renderFreeDeliveryIcon()} */}
										{renderOnSaleBadge()}
									</Stack>
								</div>

								{(isPersonalisable ||
									isSocialCause ||
									isHalal ||
									isVegan ||
									isVegetarian ||
									isNextDayDelivery) && (
									<div
										className="flex flex-row xs:px sm:px-4 py-2 text-white absolute inset-x-0 bottom-0 justify-end"
										style={{ background: "rgba(217, 217, 217, 0.80)" }}
									>
										{isPersonalisable && (
											<Tooltip title={t`Personalise your gift`} arrow>
												<div className="flex flex-row items-center pr-2 justify-center">
													<Image
														src="/icons/personalised-gift.svg"
														alt=""
														width={24}
														height={24}
													/>
												</div>
											</Tooltip>
										)}
										{isSocialCause && (
											<Tooltip title={t`Social Enterprise`} arrow>
												<div className="flex flex-row items-center pr-2 justify-center">
													<Image
														src="/icons/social-enterprise.svg"
														alt=""
														width={24}
														height={24}
													/>
												</div>
											</Tooltip>
										)}
										{isHalal && (
											<Tooltip title={t`Halal Certified`} arrow>
												<div className="flex flex-row items-center pr-2 justify-center">
													<Image
														src="/icons/halal.svg"
														alt=""
														width={24}
														height={24}
													/>
												</div>
											</Tooltip>
										)}
										{isVegan && (
											<Tooltip title={t`Vegan`} arrow>
												<div className="flex flex-row items-center pr-2 justify-center">
													<Image
														src="/icons/vegan.svg"
														alt=""
														width={24}
														height={24}
													/>
												</div>
											</Tooltip>
										)}
										{isVegetarian && (
											<Tooltip title={t`Vegetarian`} arrow>
												<div className="flex flex-row items-center pr-2 justify-center">
													<Image
														src="/icons/vegetarian.svg"
														alt=""
														width={24}
														height={24}
													/>
												</div>
											</Tooltip>
										)}
										{isNextDayDelivery && (
											<Tooltip title={t`Next Day Delivery`} arrow>
												<div className="flex flex-row items-center pr-2 justify-center">
													<Image
														src="/icons/next-day-delivery.svg"
														alt=""
														width={24}
														height={24}
													/>
												</div>
											</Tooltip>
										)}
									</div>
								)}
							</div>
						</div>
						<div className={styles.product.details}>
							<div className="pb-2 flex-1 flex flex-col">
								<div className="flex-1">
									{/* <p className={styles.product.category}>{category?.name}</p> */}
									<p className={styles.product.name}>{product.name}</p>
								</div>
								<div className="flex xs:flex-col sm:flex-row sm:justify-between sm:items-center mt-1">
									{/* {renderPrice()} */}
									<PriceComponent product={product as Product} size="small" />
									{renderFreeDelivery()}
								</div>
							</div>
						</div>
					</div>
					{/* <div className="flex flex-row justify-between text-gray-900 py-2 items-center px-4 bg-gray-50 border-t border-gray">
						<div className="text-xs uppercase">
							<Trans>Delivery</Trans>
						</div>
						<div className="flex flex-row items-center">
							<div className="w-4 h-4 border-4 border-chart-turquoiseBlue rounded-full" />
							<div className="ml-2 text-xs font-semibold text-gray-800">
								<Trans>All Regions</Trans>
							</div>
						</div>
					</div> */}
				</a>
			</Link>
		</li>
	);
};
