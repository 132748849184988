import { t } from "@lingui/macro";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Button, Menu, MenuItem } from "@mui/material";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import React from "react";

import { OrderDirection, ProductOrder, ProductOrderField } from "@/saleor/api";

export interface ProductOrderOption extends Omit<ProductOrder, "field"> {
	title?: string;
	field?: ProductOrderField | string;
}

export const PRODUCT_ORDER_OPTIONS: ProductOrderOption[] = [
	{
		title: t`Name`,
		field: ProductOrderField.Name,
		direction: OrderDirection.Asc,
	},
	{
		title: t`Price Low to High`,
		field: ProductOrderField.MinimalPrice,
		direction: OrderDirection.Asc,
	},
	{
		title: t`Price High to Low`,
		field: ProductOrderField.MinimalPrice,
		direction: OrderDirection.Desc,
	},
];

export const CART_ORDER_OPTIONS: ProductOrderOption[] = [
	{
		title: t`Asc. Recipient Name`,
		field: "profile.name",
		direction: OrderDirection.Asc,
	},
	{
		title: t`Dsc. Recipient Name`,
		field: "profile.name",
		direction: OrderDirection.Desc,
	},
	{
		title: t`Has Address`,
		field: "profile.addresses",
		direction: OrderDirection.Desc,
	},
	{
		title: t`No Address`,
		field: "profile.addresses",
		direction: OrderDirection.Asc,
	},
];

export interface SortByDropdownProps {
	onOrderChange: (order: ProductOrderOption) => void;
	options: ProductOrderOption[];
	value?: ProductOrderOption;
}

export const SortByDropdown = ({ onOrderChange, options, value }: SortByDropdownProps) => {
	const popupState = usePopupState({ variant: "popover", popupId: "sortByMenu" });

	const selectedItem =
		options.find(
			(item) => item.field === value?.field && item.direction === value?.direction
		) || options[0];

	const handleItemSelected = (option: ProductOrderOption) => () => {
		popupState.close();
		onOrderChange(option);
	};

	return (
		<div>
			<Button
				variant="outlined"
				color="inherit"
				className="capitalize ml-4"
				endIcon={<KeyboardArrowDownIcon style={{ fontSize: 26 }} />}
				{...bindTrigger(popupState)}
			>
				{selectedItem.title}
			</Button>
			<Menu {...bindMenu(popupState)}>
				{options.map((option) => (
					<MenuItem key={option.title} onClick={handleItemSelected(option)}>
						{option.title}
					</MenuItem>
				))}
			</Menu>
		</div>
	);
};

export default SortByDropdown;
