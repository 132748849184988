import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 65 64" {...props}>
			<path
				d="M47.9851 63.998C47.4541 63.998 47.0243 63.573 47.0243 63.048C47.0243 62.1479 46.7589 61.7479 46.3796 61.1979C45.9498 60.5603 45.4062 59.7603 45.4062 58.2852C45.4062 56.7976 45.9498 56.0101 46.3796 55.3726C46.7589 54.8225 47.0243 54.4225 47.0243 53.5225C47.0243 52.6224 46.7589 52.2224 46.3796 51.6724C45.9498 51.0348 45.4062 50.2348 45.4062 48.7597C45.4062 47.2721 45.9498 46.4846 46.3796 45.847C46.7589 45.297 47.0243 44.897 47.0243 43.9969C47.0243 43.4719 47.4541 43.0469 47.9851 43.0469C48.516 43.0469 48.9458 43.4719 48.9458 43.9969C48.9458 45.4845 48.4022 46.2721 47.9724 46.9096C47.5932 47.4596 47.3277 47.8596 47.3277 48.7597C47.3277 49.6597 47.5932 50.0598 47.9724 50.6098C48.4022 51.2473 48.9458 52.0474 48.9458 53.5225C48.9458 55.01 48.4022 55.7976 47.9724 56.4351C47.5932 56.9851 47.3277 57.3852 47.3277 58.2852C47.3277 59.1853 47.5932 59.5853 47.9724 60.1353C48.4022 60.7729 48.9458 61.5729 48.9458 63.048C48.9585 63.573 48.516 63.998 47.9851 63.998Z"
				fill="#FDD330"
			/>
			<path
				d="M49.7055 45.3659H45.9637C45.2053 45.3659 44.6238 44.6909 44.7628 43.9409L45.6098 39.1406H50.0595L50.9064 43.9409C51.0455 44.6909 50.4766 45.3659 49.7055 45.3659Z"
				fill="#1785F8"
			/>
			<path
				d="M49.7064 45.3659H46.6094C47.3678 45.3659 47.9493 44.6909 47.8103 43.9409L46.9633 39.1406H50.0604L50.9073 43.9409C51.0464 44.6909 50.4649 45.3659 49.7064 45.3659Z"
				fill="#1576F7"
			/>
			<path
				d="M60.4968 22.5726C60.4968 31.8856 54.8336 40.8861 47.8305 40.8861C40.8273 40.8861 35.1641 31.8856 35.1641 22.5726C35.1641 13.2597 40.8399 7.17188 47.8431 7.17188C54.8463 7.17188 60.4968 13.2722 60.4968 22.5726Z"
				fill="#31A7FB"
			/>
			<path
				d="M44.526 40.2025C39.773 38.2024 36.1197 32.0771 35.3359 25.3143C37.2447 23.8017 40.4682 22.2266 44.4502 24.0017C49.987 26.4643 50.3157 33.6897 44.526 40.2025Z"
				fill="#1C96F9"
			/>
			<path
				d="M60.5013 22.5758C60.5013 31.8887 54.838 40.8892 47.8348 40.8892C46.5581 40.8892 45.3192 40.5892 44.1562 40.0391C51.0204 39.8016 56.532 30.9137 56.532 21.7382C56.532 14.4754 53.0809 9.1751 48.252 7.1875C55.0529 7.41251 60.5013 13.4503 60.5013 22.5758Z"
				fill="#1C96F9"
			/>
			<path
				d="M30.3514 63.7211C29.8204 63.7211 29.3906 63.2961 29.3906 62.7711C29.3906 61.2835 29.9342 60.4835 30.364 59.8334C30.7432 59.2709 31.0213 58.8709 31.0213 57.9583C31.0213 57.0458 30.7432 56.6458 30.364 56.0832C29.9342 55.4457 29.3906 54.6457 29.3906 53.1456C29.3906 51.6455 29.9342 50.858 30.364 50.208C30.7432 49.6454 31.0213 49.2454 31.0213 48.3329C31.0213 47.8078 31.4511 47.3828 31.9821 47.3828C32.513 47.3828 32.9428 47.8078 32.9428 48.3329C32.9428 49.8204 32.3992 50.6205 31.9694 51.2705C31.5902 51.833 31.3121 52.2331 31.3121 53.1456C31.3121 54.0581 31.5902 54.4582 31.9694 55.0207C32.3992 55.6582 32.9428 56.4583 32.9428 57.9583C32.9428 59.4459 32.3992 60.246 31.9694 60.896C31.5902 61.4585 31.3121 61.8585 31.3121 62.7711C31.3121 63.2961 30.8823 63.7211 30.3514 63.7211Z"
				fill="#1576F7"
			/>
			<path
				d="M33.6117 49.2487H29.87C29.1115 49.2487 28.53 48.5737 28.6691 47.8237L29.516 43.0234H33.9657L34.8126 47.8237C34.9517 48.5737 34.3828 49.2487 33.6117 49.2487Z"
				fill="#FB3A82"
			/>
			<path
				d="M33.6151 49.2487H30.1641C30.9225 49.2487 31.504 48.5737 31.365 47.8237L30.518 43.0234H33.9817L34.8287 47.8237C34.9551 48.5737 34.3862 49.2487 33.6151 49.2487Z"
				fill="#FB1466"
			/>
			<path
				d="M42.7903 21.8004C38.4923 19.8878 35.0919 21.8754 33.2463 23.463C32.3867 24.2006 31.1099 24.2006 30.2503 23.463C28.4174 21.8629 25.0043 19.8753 20.7063 21.8004C12.9447 25.2506 15.4223 38.0888 30.6549 45.1767C31.3501 45.5017 32.1465 45.5017 32.8418 45.1767C48.0743 38.0888 50.552 25.2506 42.7903 21.8004Z"
				fill="#FC95BB"
			/>
			<path
				d="M30.9711 23.8847C30.2885 25.8973 29.3657 27.7849 28.2533 29.4475C26.6099 31.8976 24.6506 33.7352 22.5016 34.8727L22.7544 36.3228C22.944 37.4104 22.6533 38.5104 21.9454 39.3605C14.8284 32.5226 14.7779 24.4222 20.7065 21.7845C25.0045 19.8719 28.405 21.8596 30.2506 23.4596C30.4781 23.6596 30.7183 23.7972 30.9711 23.8847Z"
				fill="#FC78A8"
			/>
			<path
				d="M32.8434 45.1715C32.1482 45.4965 31.3518 45.4965 30.6565 45.1715C29.9739 44.8464 29.3039 44.5214 28.6719 44.1714C28.9121 44.1339 29.1649 44.0589 29.3924 43.9589C43.9802 37.1711 46.875 25.108 40.2637 21.0703C41.0601 21.1703 41.907 21.3953 42.7919 21.7953C50.5535 25.2455 48.0759 38.0836 32.8434 45.1715Z"
				fill="#FC78A8"
			/>
			<path
				d="M15.5388 53.9196C15.0079 53.9196 14.5781 53.4946 14.5781 52.9696C14.5781 51.357 15.1596 50.4945 15.64 49.7944C16.0445 49.1944 16.3732 48.7194 16.3732 47.6818C16.3732 46.6568 16.0445 46.1692 15.64 45.5692C15.1723 44.8692 14.5781 44.0066 14.5781 42.3941C14.5781 40.7815 15.1596 39.9189 15.64 39.2189C16.0445 38.6189 16.3732 38.1438 16.3732 37.1063C16.3732 36.5813 16.803 36.1562 17.3339 36.1562C17.8648 36.1562 18.2946 36.5813 18.2946 37.1063C18.2946 38.7189 17.7131 39.5814 17.2328 40.2815C16.8282 40.8815 16.4996 41.3565 16.4996 42.3941C16.4996 43.4191 16.8282 43.9066 17.2328 44.5067C17.7005 45.2067 18.2946 46.0692 18.2946 47.6818C18.2946 49.2944 17.7131 50.1569 17.2328 50.857C16.8282 51.457 16.4996 51.932 16.4996 52.9696C16.4996 53.4946 16.0698 53.9196 15.5388 53.9196Z"
				fill="#FC95BB"
			/>
			<path
				d="M19.0288 38.1941H15.2997C14.5412 38.1941 13.9597 37.5191 14.0988 36.769L14.9457 31.9688H19.3954L20.2423 36.769C20.3687 37.5191 19.7999 38.1941 19.0288 38.1941Z"
				fill="#F8C52D"
			/>
			<path
				d="M19.0424 38.1941H15.9453C16.7038 38.1941 17.2853 37.5191 17.1462 36.769L16.2993 31.9688H19.3963L20.2433 36.769C20.3697 37.5191 19.8008 38.1941 19.0424 38.1941Z"
				fill="#D6A826"
			/>
			<path
				d="M29.8201 15.4008C29.8201 24.7137 24.1569 33.7142 17.1537 33.7142C10.1506 33.7142 4.5 24.7137 4.5 15.4008C4.5 6.0878 10.1632 0 17.1664 0C24.1695 0 29.8201 6.1003 29.8201 15.4008Z"
				fill="#FEE265"
			/>
			<path
				d="M29.8215 15.4008C29.8215 24.7137 24.1457 33.7142 17.1552 33.7142C16.1312 33.7142 15.1326 33.5267 14.1719 33.1641C20.7452 32.4266 25.9281 23.8137 25.9281 14.9007C25.9281 7.17536 22.022 1.66258 16.6875 0.0125006C16.8518 0 17.0035 0 17.1678 0C24.1583 0 29.8215 6.1003 29.8215 15.4008Z"
				fill="#FDD330"
			/>
		</SvgIcon>
	);
};

export default Icon;
