import { t, Trans } from "@lingui/macro";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { bindMenu, bindTrigger, usePopupState } from "material-ui-popup-state/hooks";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { MouseEventHandler } from "react";

import { ProductDetailsFragment, ProductVariantFragment } from "@/saleor/api";

import useMultipleCheckoutHook from "./utils/useMultipleCheckoutHook";

export interface VariantSelectorProps {
	product: ProductDetailsFragment;
	selectedVariantID?: string;
}

export const VariantSelector = ({ product, selectedVariantID }: VariantSelectorProps) => {
	const router = useRouter();
	const popupState = usePopupState({ variant: "popover", popupId: "variant-selector" });
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("sm"));
	const isMultipleCheckout = useMultipleCheckoutHook();

	const variants = product.variants;
	if (!variants || variants.length === 1) {
		return null;
	}

	const handleClick = (variant: ProductVariantFragment | null) => () => {
		popupState.close();
		router.push({
			pathname: isMultipleCheckout ? "/multiple/products/[slug]" : "/products/[slug]",
			query: { variant: variant?.id, slug: product.slug },
		});
	};

	const handleMenuItemClick = (variant: ProductVariantFragment | null) => {
		// console.log("Clicked!");
		router.push(
			{
				pathname: isMultipleCheckout ? "/multiple/products/[slug]" : "/products/[slug]",
				query: { variant: variant?.id, slug: product.slug },
			},
			undefined,
			{ shallow: true }
		);
		popupState.close();
	};

	const renderSelection = () => {
		if (variants.length > 3 || matches) {
			const selectedVariant = variants.find((v) => v?.id === selectedVariantID);
			return (
				<div className="flex col-span-3 justify-end">
					<Button
						variant="outlined"
						{...bindTrigger(popupState)}
						endIcon={<KeyboardArrowDownIcon />}
					>
						{selectedVariant?.name || t`Select Variant`}
					</Button>
					<Menu {...bindMenu(popupState)}>
						{variants.map((variant) => {
							const isSelected = variant?.id === selectedVariantID;
							return (
								<MenuItem
									className="font-semibold"
									// onClick={() => handleClick(variant)}
									onClick={() => handleMenuItemClick(variant)}
									disabled={variant?.quantityAvailable === 0}
									key={variant?.id}
									selected={isSelected}
								>
									{/* <Link
										href={{
											pathname: isMultipleCheckout
												? "/multiple/products/[slug]"
												: "/products/[slug]",
											query: { variant: variant?.id, slug: product.slug },
										}}
										replace
										shallow
										passHref
									>
										<a className="w-full">{variant?.name}</a>
									</Link> */}
									{variant?.name}
								</MenuItem>
							);
						})}
					</Menu>
				</div>
			);
		}

		return (
			<div className="col-span-4 md:col-span-3">
				<ButtonGroup>
					{variants.map((variant) => {
						const isSelected = variant?.id === selectedVariantID;
						return (
							<Link
								key={variant?.name}
								href={{
									pathname: isMultipleCheckout
										? "/multiple/products/[slug]"
										: "/products/[slug]",
									query: { variant: variant?.id, slug: product.slug },
								}}
								replace
								passHref
								shallow
							>
								<Button
									disableElevation
									className={clsx({
										"text-white": isSelected,
									})}
									disabled={variant?.quantityAvailable === 0}
									variant={isSelected ? "contained" : "outlined"}
									key={variant?.name}
								>
									{variant?.name}
								</Button>
							</Link>
						);
					})}
				</ButtonGroup>
			</div>
		);
	};

	return (
		<div className="grid grid-cols-4 gap-2 items-center">
			<div className="col-span-1 text-sm font-semibold">
				{variants[0]?.attributes[0].attribute.name}
			</div>

			{renderSelection()}
		</div>
	);
};

export default VariantSelector;
