import { Plural, t, Trans } from "@lingui/macro";
import AddIcon from "@mui/icons-material/Add";
import MessageIcon from "@mui/icons-material/Message";
import { Button, Stack } from "@mui/material";
import moment from "moment";
import numbro from "numbro";
import React from "react";

import { MerchantFragment } from "@/saleor/api";

export interface MerchantPageHeroProps {
	entity: MerchantFragment;
}

export const MerchantPageHero: React.VFC<MerchantPageHeroProps> = ({ entity }) => {
	const style: React.CSSProperties = {
		backgroundImage:
			"url(https://goalsmapper-rewards-staging.s3.ap-southeast-1.amazonaws.com/app-assets/dev/supreme-bg.png)",
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
	};

	return (
		<div
			className="container mx-auto rounded-md flex items-center relative w-full aspect-[7.45/1] min-h-fit"
			style={style}
		>
			<div className="absolute inset-0" style={{ backgroundColor: "rgba(0, 0, 0, 0.38)" }} />

			<Stack
				direction="row"
				className="absolute inset-0"
				justifyContent="space-between"
				alignItems="center"
			>
				<Stack
					direction="row"
					spacing={3}
					style={{ backgroundColor: "rgba(0, 0, 0, 0.54)" }}
					className="min-w-[50%] rounded-r-2xl"
					justifyContent="space-between"
				>
					<Stack direction="row" py={2} spacing={3} pl={3}>
						<div
							className="w-14 h-14 rounded-full"
							style={{
								backgroundImage:
									"url(https://goalsmapper-rewards-staging.s3.ap-southeast-1.amazonaws.com/app-assets/dev/suprem-avatar.png)",
								backgroundRepeat: "no-repeat",
								backgroundSize: "cover",
							}}
						/>
						<Stack direction="column" pt={1}>
							<div className="text-md font-semibold text-white">{entity.name}</div>
						</Stack>
					</Stack>
					<Stack
						direction="row"
						className="rounded-r-2xl text-white"
						px={2}
						style={{ backgroundColor: "rgba(0, 0, 0, 0.87)" }}
					>
						<Button color="inherit" startIcon={<MessageIcon />}>
							<Trans>Chat</Trans>
						</Button>
						<Button color="inherit" startIcon={<AddIcon />}>
							<Trans>Follow</Trans>
						</Button>
					</Stack>
				</Stack>

				<Stack
					direction="row"
					style={{ backgroundColor: "rgba(0, 0, 0, 0.87)" }}
					className="text-white"
					px={3}
					spacing={3}
					py={1.5}
				>
					<Stack>
						<div className="text-xs uppercase">
							<Trans>Products</Trans>
						</div>
						<div className="text-base font-semibold">
							{numbro(entity.products?.totalCount).format("0,0")}
						</div>
					</Stack>
					<Stack>
						<div className="text-xs uppercase">
							<Trans>Followers</Trans>
						</div>
						<div className="text-base font-semibold">
							{numbro(entity.products?.totalCount).format("0,0")}
						</div>
					</Stack>
					{/* <Stack>
						<div className="text-xs uppercase">
							<Trans>Date Joined</Trans>
						</div>
						<div className="text-base font-semibold">
							{moment(entity.user?.dateJoined).fromNow()}
						</div>
					</Stack> */}
				</Stack>
			</Stack>
		</div>
	);
};

export default MerchantPageHero;
