import { AddressDetailsFragment } from "@/saleor/api";

export interface AddressDisplayProps {
	address: AddressDetailsFragment;
}

export const AddressDisplay = ({ address }: AddressDisplayProps) => {
	return (
		<div>
			<address className="not-italic mb-2">
				<p>
					{address?.firstName} {address?.lastName}
				</p>
				<p>{address?.streetAddress1}</p>
				{address?.streetAddress2 && <p>{address?.streetAddress2}</p>}
				<p>
					{[address?.unitNumber, address.postalCode, address.city]
						.filter((c) => !!c)
						.join(", ")}
					, {address?.country.country}
				</p>
			</address>
			<div>{address?.phone}</div>
		</div>
	);
};

export default AddressDisplay;
