import { Trans } from "@lingui/macro";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Typography } from "@mui/material";
import clsx from "clsx";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";
import { useToggle } from "react-use";

import { useAllCategoriesQuery } from "@/saleor/api";

import useChannelContext from "./shared/contexts/ChannelContext";

const CategoriesSideBar = () => {
	const router = useRouter();
	const { channel } = useChannelContext();
	const [showAll, toggleShowAll] = useToggle(false);

	const { data, loading } = useAllCategoriesQuery({
		variables: {
			productCountGte: 1,
			channel,
		},
	});
	if (loading) {
		return <div />;
	}

	const SHOW_MIN = 10;

	const categories = data?.categories?.edges.map((e) => e.node);
	const displayCategories = showAll ? categories : categories?.slice(0, SHOW_MIN);

	const activeSlug = router.route.startsWith("/category") ? router.query.slug : null;
	return (
		<Box minWidth={250}>
			<Typography className="pb-2" variant="h6">
				<Trans>Shop by Category</Trans>
			</Typography>
			{displayCategories?.map((cat) => {
				return (
					<div key={cat.id} className="py-2 text-sm">
						<Link href={`/category/${cat.slug}`} passHref>
							<a
								className={clsx("hover:text-brand-blue", {
									"text-black": cat.slug !== activeSlug,
									"font-semibold": cat.slug === activeSlug,
								})}
							>
								{cat.name} ({cat.products?.totalCount || 0})
							</a>
						</Link>
					</div>
				);
			})}
			{!showAll && (categories?.length || 0) > SHOW_MIN && (
				<Button
					onClick={toggleShowAll}
					className="px-0 mt-2 text-sm capitalize"
					startIcon={<ArrowForwardIosIcon style={{ fontSize: 12 }} />}
				>
					<Trans>View more</Trans>
				</Button>
			)}
		</Box>
	);
};

export default CategoriesSideBar;
