import "yup-phone";

import { yupResolver } from "@hookform/resolvers/yup";
import { t, Trans } from "@lingui/macro";
import { CircularProgress } from "@mui/material";
import React from "react";
import { useForm } from "react-hook-form";
import { useToggle } from "react-use";
import * as yup from "yup";

import { AccountError, AddressDetailsFragment, CheckoutError, CountryCode } from "@/saleor/api";

const addressSchema = yup
	.object({
		phone: yup
			.string()
			.label(t`Phone`)
			.phone("SG", true)
			.required(),
		firstName: yup
			.string()
			.label(t`First Name`)
			.required(),
		lastName: yup
			.string()
			.label(t`Last Name`)
			.notRequired(),
		streetAddress1: yup
			.string()
			.label(t`Street Address 1`)
			.required(),
		streetAddress2: yup
			.string()
			.label(t`Street Address 2`)
			.notRequired(),
		city: yup
			.string()
			.label(t`City`)
			.required(),
		country: yup
			.mixed<CountryCode>()
			.label(t`Country`)
			.required(),
		postalCode: yup
			.string()
			.label(t`Postal Code`)
			.required(),
		unitNumber: yup
			.string()
			.label(t`Unit No`)
			.notRequired(),
	})
	.required();

export type AddressFormData = yup.InferType<typeof addressSchema>;

export interface AddressFormProps {
	existingAddressData?: AddressDetailsFragment;
	toggleEdit: () => void;
	updateAddressMutation: (address: AddressFormData) => Promise<CheckoutError[] | AccountError[]>;
	className?: string;
	cancelable?: boolean;
}

export const AddressForm = ({
	existingAddressData,
	toggleEdit,
	updateAddressMutation,
	className,
	cancelable = false,
}: AddressFormProps) => {
	const [isProcessing, toggleProcessing] = useToggle(false);
	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
	} = useForm<AddressFormData>({
		resolver: yupResolver(addressSchema),
		defaultValues: {
			firstName: existingAddressData?.firstName || "",
			lastName: existingAddressData?.lastName || "",
			phone: existingAddressData?.phone || "",
			country: (existingAddressData?.country?.code as CountryCode) || CountryCode.Sg,
			streetAddress1: existingAddressData?.streetAddress1 || "",
			streetAddress2: existingAddressData?.streetAddress2 || "",
			city: existingAddressData?.city || "",
			postalCode: existingAddressData?.postalCode || "",
			unitNumber: existingAddressData?.unitNumber || "",
		},
	});

	const onAddressFormSubmit = handleSubmit(async (formData: AddressFormData) => {
		toggleProcessing();

		const errors = await updateAddressMutation(formData);

		toggleProcessing();
		// Assign errors to the form fields
		if (errors.length > 0) {
			errors.forEach((e) =>
				setError(e.field as keyof AddressFormData, {
					message: e.message || "",
				})
			);
			return;
		}

		// Address updated, we can exit the edit mode
		toggleEdit();
	});

	return (
		<form onSubmit={onAddressFormSubmit}>
			<div className={`grid grid-cols-12 gap-4 w-full ${className}`}>
				<div className="col-span-full">
					<label htmlFor="address" className="block text-sm font-medium text-gray-700">
						<Trans>Phone</Trans>
					</label>
					<div className="mt-1">
						<input
							type="tel"
							id="phone"
							className="w-full border-gray-300 rounded-md shadow-sm text-sm"
							{...register("phone")}
						/>
						{errors.phone && <p className="text-red-600">{errors.phone.message}</p>}
					</div>
				</div>

				<div className="col-span-full sm:col-span-6">
					<label htmlFor="firstName" className="block text-sm font-medium text-gray-700">
						<Trans>First Name</Trans>
					</label>
					<div className="mt-1">
						<input
							type="text"
							id="province"
							className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							{...register("firstName")}
						/>
						{!!errors.firstName && (
							<p className="text-red-600">{errors.firstName.message}</p>
						)}
					</div>
				</div>

				<div className="col-span-full sm:col-span-6">
					<label htmlFor="lastName" className="block text-sm font-medium text-gray-700">
						<Trans>Last Name</Trans>
					</label>
					<div className="mt-1">
						<input
							type="text"
							id="lastName"
							className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							{...register("lastName")}
						/>
						{!!errors.lastName && (
							<p className="text-red-600">{errors.lastName.message}</p>
						)}
					</div>
				</div>

				<div className="col-span-full">
					<label
						htmlFor="streetAddress1"
						className="block text-sm font-medium text-gray-700"
					>
						<Trans>Street Address 1</Trans>
					</label>
					<div className="mt-1">
						<input
							type="text"
							id="streetAddress1"
							className="w-full border-gray-300 rounded-md shadow-sm text-sm"
							{...register("streetAddress1")}
						/>
						{!!errors.streetAddress1 && (
							<p className="text-red-600">{errors.streetAddress1.message}</p>
						)}
					</div>
				</div>

				<div className="col-span-full">
					<label
						htmlFor="streetAddress2"
						className="block text-sm font-medium text-gray-700"
					>
						<Trans>Street Address 2</Trans>
					</label>
					<div className="mt-1">
						<input
							type="text"
							id="streetAddress2"
							className="w-full border-gray-300 rounded-md shadow-sm text-sm"
							{...register("streetAddress2")}
						/>
						{!!errors.streetAddress2 && (
							<p className="text-red-600">{errors.streetAddress2.message}</p>
						)}
					</div>
				</div>

				<div className="col-span-full">
					<label htmlFor="unitNumber" className="block text-sm font-medium text-gray-700">
						<Trans>Unit No</Trans>
					</label>
					<div className="mt-1">
						<input
							type="text"
							id="streetAddress2"
							className="w-full border-gray-300 rounded-md shadow-sm text-sm"
							{...register("unitNumber")}
						/>
						{!!errors.unitNumber && (
							<p className="text-red-600">{errors.unitNumber.message}</p>
						)}
					</div>
				</div>

				<div className="col-span-full sm:col-span-6">
					<label htmlFor="city" className="block text-sm font-medium text-gray-700">
						<Trans>City</Trans>
					</label>
					<div className="mt-1">
						<input
							type="text"
							id="city"
							className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							{...register("city")}
						/>
						{!!errors.city && <p className="text-red-600">{errors.city.message}</p>}
					</div>
				</div>

				<div className="col-span-full sm:col-span-6">
					<label
						htmlFor="postal-code"
						className="block text-sm font-medium text-gray-700"
					>
						<Trans>Postal code</Trans>
					</label>
					<div className="mt-1">
						<input
							type="text"
							id="postal-code"
							autoComplete="postal-code"
							className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
							{...register("postalCode")}
						/>
						{!!errors.postalCode && (
							<p className="text-red-600">{errors.postalCode.message}</p>
						)}
					</div>
				</div>

				{cancelable && (
					<div className="col-span-6">
						<button
							className="w-full border border-blue-300 rounded-md shadow-sm py-2 px-4 text-sm font-medium hover:bg-blue-100"
							onClick={toggleEdit}
							disabled={isProcessing}
						>
							<Trans>Cancel</Trans>
						</button>
					</div>
				)}
				<div className={cancelable ? "col-span-6" : "col-span-full"}>
					<button className="btn-checkout-section" type="submit" disabled={isProcessing}>
						{isProcessing ? <CircularProgress size={15} /> : <Trans>Save</Trans>}
					</button>
				</div>
			</div>
		</form>
	);
};
