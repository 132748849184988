import { t, Trans } from "@lingui/macro";
import { Button, CircularProgress, Stack } from "@mui/material";

import AddressDisplay from "@/components/checkout/AddressDisplay";
import {
	AddressDetailsFragment,
	AddressTypeEnum,
	useAddressDeleteMutation,
	useSetAddressDefaultMutation,
} from "@/saleor/api";

import CreateUpdateAddressButton from "./CreateUpdateAddressButton";

interface AddressBookCardProps {
	address: AddressDetailsFragment;
	onRefreshBook: () => void;
}

export const AddressBookCard = ({ address, onRefreshBook }: AddressBookCardProps) => {
	const [setDefaultBilling, { loading: processingBilling }] = useSetAddressDefaultMutation({
		variables: { id: address.id, type: AddressTypeEnum.Billing },
		onCompleted: onRefreshBook,
	});
	const [setDefaultShipping, { loading: processingShipping }] = useSetAddressDefaultMutation({
		variables: { id: address.id, type: AddressTypeEnum.Shipping },
		onCompleted: onRefreshBook,
	});

	const [deleteAddressMutation, { loading }] = useAddressDeleteMutation({
		variables: { id: address.id },
		onCompleted: onRefreshBook,
	});

	let cardHeader = "";
	if (address.isDefaultShippingAddress && address.isDefaultBillingAddress) {
		cardHeader = t`Default billing and shipping address`;
	} else if (address.isDefaultShippingAddress) {
		cardHeader = t`Default shipping address`;
	} else if (address.isDefaultBillingAddress) {
		cardHeader = t`Default billing address`;
	}

	return (
		<div className="justify-between flex flex-col checkout-section-container">
			{!!cardHeader && <p className="text-md font-semibold mb-1">{cardHeader}</p>}
			<AddressDisplay address={address} />
			<Stack direction="column" spacing={1} mt={2}>
				{!address.isDefaultBillingAddress && (
					<Button
						className="capitalize"
						variant="outlined"
						onClick={() => setDefaultBilling()}
						disabled={processingBilling}
						endIcon={loading ? <CircularProgress size={20} /> : null}
					>
						<Trans>Set as billing default</Trans>
					</Button>
				)}
				{!address.isDefaultShippingAddress && (
					<Button
						className="capitalize"
						variant="outlined"
						onClick={() => setDefaultShipping()}
						disabled={processingShipping}
						endIcon={loading ? <CircularProgress size={20} /> : null}
					>
						<Trans>Set as shipping default</Trans>
					</Button>
				)}
				<Stack direction="row" spacing={1}>
					<CreateUpdateAddressButton
						title={t`Edit`}
						dialogTitle={t`Edit address`}
						variant="update"
						onCompleted={onRefreshBook}
						address={address}
					/>
					<Button
						className="capitalize"
						fullWidth
						disabled={loading}
						variant="outlined"
						onClick={() => deleteAddressMutation()}
						color="error"
						endIcon={loading ? <CircularProgress size={20} /> : null}
					>
						<Trans>Remove</Trans>
					</Button>
				</Stack>
			</Stack>
		</div>
	);
};
