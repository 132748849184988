import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

const Icon = (props: SvgIconProps) => {
	return (
		<SvgIcon viewBox="0 0 512 512" {...props}>
			<path
				d="M256 464L57.976 256C12.676 208.417 12.676 131.271 57.976 83.687C103.276 36.103 176.723 36.104 222.024 83.687L256 119.375L289.976 83.687C335.276 36.104 408.723 36.104 454.024 83.687C499.325 131.27 499.324 208.417 454.024 256L256 464Z"
				fill="#F94267"
			/>
			<path
				d="M256 264C158.8 264 88 359.87 88 488V504H424V488C424 359.87 353.2 264 256 264Z"
				fill="#4AB4E6"
			/>
			<path
				d="M306.374 386.374L296 376H216L205.626 386.374C196.59 395.41 190.049 406.632 186.638 418.947C183.227 431.262 183.063 444.25 186.162 456.647L188 464H324L325.838 456.647C328.937 444.25 328.774 431.262 325.363 418.947C321.952 406.632 315.41 395.41 306.374 386.374V386.374Z"
				fill="#FBA35F"
			/>
			<path
				d="M192 472H200V456H192.662L145.315 448.108L142.685 463.892L190.685 471.892C191.12 471.964 191.56 472 192 472V472Z"
				fill="#3D95D6"
			/>
			<path
				d="M294 8H218C190.386 8 168 30.3858 168 58V86C168 113.614 190.386 136 218 136H294C321.614 136 344 113.614 344 86V58C344 30.3858 321.614 8 294 8Z"
				fill="#D76B4C"
			/>
			<path
				d="M215.443 272L221.523 281.728C237.189 306.795 273.696 306.795 289.363 281.728L295.443 272L279.443 208H231.443L215.443 272Z"
				fill="#FFD4CF"
			/>
			<path
				d="M225.687 231.024L226.429 231.488C235.26 237.007 245.454 239.955 255.867 239.999C266.281 240.044 276.5 237.185 285.378 231.742L279.443 208H231.443L225.687 231.024Z"
				fill="#FBB8B0"
			/>
			<path
				d="M340 112H320V103.1H301.3C287.485 103.1 273.906 99.5228 261.884 92.7163C249.862 85.9098 239.808 76.1061 232.7 64.26L232 63.1L192 103.1V112H172C166.696 112 161.609 114.107 157.858 117.858C154.107 121.609 152 126.696 152 132V148C152 153.304 154.107 158.391 157.858 162.142C161.609 165.893 166.696 168 172 168H192C192 168 200.881 196.653 215.5 205.79L234.908 217.92C241.239 221.877 248.55 223.984 256.016 224.001C263.481 224.019 270.803 221.947 277.152 218.02L296.3 206.169C311.035 197.057 320 168 320 168H340C345.304 168 350.391 165.893 354.142 162.142C357.893 158.391 360 153.304 360 148V132C360 126.696 357.893 121.609 354.142 117.858C350.391 114.107 345.304 112 340 112V112Z"
				fill="#FFD4CF"
			/>
			<path
				d="M256.5 202C245.565 202 237 194.972 237 186H250C250 187.128 252.468 189.2 256.5 189.2C260.532 189.2 263 187.128 263 186H276C276 194.972 267.435 202 256.5 202Z"
				fill="#AE7069"
			/>
			<path
				d="M256.5 162C276.967 162 293 171.224 293 183H268.667C268.667 181.52 264.048 178.8 256.5 178.8C248.952 178.8 244.333 181.52 244.333 183H220C220 171.224 236.033 162 256.5 162Z"
				fill="#AE7069"
			/>
			<path d="M280 128H296V144H280V128Z" fill="#AE7069" />
			<path d="M216 128H232V144H216V128Z" fill="#AE7069" />
			<path
				d="M312 312C310.275 311.999 308.554 312.184 306.868 312.55C302.384 302.832 295.21 294.602 286.196 288.834C277.181 283.065 266.702 279.999 256 279.999C245.298 279.999 234.819 283.065 225.804 288.834C216.79 294.602 209.616 302.832 205.132 312.55C201.629 311.787 197.999 311.818 194.509 312.642C191.02 313.466 187.759 315.061 184.967 317.31C182.175 319.56 179.922 322.406 178.374 325.64C176.827 328.875 176.023 332.415 176.023 336C176.023 339.585 176.827 343.125 178.374 346.36C179.922 349.594 182.175 352.44 184.967 354.69C187.759 356.939 191.02 358.534 194.509 359.358C197.999 360.182 201.629 360.213 205.132 359.45C209.616 369.168 216.79 377.398 225.804 383.166C234.819 388.935 245.298 392.001 256 392.001C266.702 392.001 277.181 388.935 286.196 383.166C295.21 377.398 302.384 369.168 306.868 359.45C310.158 360.167 313.563 360.183 316.86 359.498C320.157 358.813 323.273 357.442 326.005 355.473C328.737 353.505 331.025 350.984 332.719 348.073C334.413 345.163 335.475 341.929 335.837 338.581C336.199 335.233 335.853 331.846 334.82 328.641C333.788 325.436 332.092 322.484 329.844 319.977C327.596 317.47 324.845 315.464 321.771 314.089C318.697 312.715 315.367 312.003 312 312V312Z"
				fill="#FFD4CF"
			/>
			<path
				d="M264 320V304H262.451C258.62 303.996 254.946 302.472 252.237 299.763C249.528 297.054 248.004 293.38 248 289.549V280.568C242.454 281.36 237.06 282.986 232 285.39V289.549C232.009 297.622 235.22 305.362 240.929 311.071C246.638 316.78 254.378 319.991 262.451 320H264Z"
				fill="#FBA35F"
			/>
			<path d="M224 320H240V336H224V320Z" fill="#AE7069" />
			<path d="M272 320H288V336H272V320Z" fill="#AE7069" />
			<path d="M240 352H272V368H240V352Z" fill="#AE7069" />
			<path
				d="M291.224 429.037C287.909 425.807 283.465 424 278.837 424C274.209 424 269.764 425.807 266.45 429.037L256.16 438.018L245.905 429.037C242.59 425.807 238.146 424 233.518 424C228.89 424 224.445 425.807 221.131 429.037C219.507 430.619 218.216 432.51 217.335 434.598C216.454 436.687 216 438.931 216 441.198C216 443.464 216.454 445.708 217.335 447.797C218.216 449.885 219.507 451.776 221.131 453.358L256.178 487.051L291.224 453.358C292.848 451.776 294.139 449.885 295.02 447.797C295.901 445.708 296.355 443.464 296.355 441.198C296.355 438.931 295.901 436.687 295.02 434.598C294.139 432.51 292.848 430.619 291.224 429.037Z"
				fill="#F94267"
			/>
			<path
				d="M192 456H220.932C230.233 456 239.152 459.695 245.729 466.271C252.305 472.848 256 481.767 256 491.068V504H192V456Z"
				fill="#FFD4CF"
			/>
			<path
				d="M320 472H312V456H319.338L366.685 448.108L369.315 463.892L321.315 471.892C320.88 471.964 320.441 472 320 472V472Z"
				fill="#3D95D6"
			/>
			<path
				d="M320 504H256V491.068C256 481.767 259.695 472.848 266.271 466.271C272.848 459.695 281.767 456 291.068 456H320V504Z"
				fill="#FFD4CF"
			/>
		</SvgIcon>
	);
};

export default Icon;
