import { Trans } from "@lingui/macro";
import { Chip } from "@mui/material";
import { useAuthState } from "@saleor/sdk";
import clsx from "clsx";
import { useField } from "formik";
import { useEffect } from "react";

import { useAvailableCoinForCheckoutsQuery } from "@/saleor/api";

import useChannelContext from "../shared/contexts/ChannelContext";
import useLoadingContext from "../shared/contexts/LoadingContext";
import { Spinner } from "../Spinner";

export interface RedeemCoinComponentProps {
	tokens: string[];
}

const RedeemCoinComponent = ({ tokens }: RedeemCoinComponentProps) => {
	const { authenticated } = useAuthState();
	const { loading, setLoading } = useLoadingContext();
	const [field, meta, helpers] = useField({
		name: "redeemCoinPosition",
	});

	const PART_COUNT = 4;
	const { channel } = useChannelContext();

	const { data, loading: coinsLoading } = useAvailableCoinForCheckoutsQuery({
		variables: { tokens, channel },
		skip: !authenticated || typeof window === "undefined",
	});

	useEffect(() => {
		setLoading(coinsLoading);
	}, [coinsLoading, setLoading]);

	if (loading) {
		return <Spinner />;
	}

	const availableCoin = data?.availableCoin || 0;
	const partValue = 1 / PART_COUNT;
	const sectionValue = availableCoin * partValue;

	const selectedIndex = field.value * PART_COUNT - 1;

	const onPartSelected = (index: number) => {
		helpers.setValue((index + 1) * partValue);
	};

	const sections = [0, 0, 0, 0];

	if (availableCoin <= 0) {
		return null;
	}

	return (
		<div className="flex flex-col w-full">
			<div className="flex flex-col w-full pt-2">
				<div className="flex justify-center content-center items-center text-gray-600">
					<p>
						<Trans>Select how many coins to redeem</Trans>
					</p>
					{/* <div className="flex pl-4">
						<HelpOutline />
					</div> */}
				</div>
				<div className="flex justify-between content-center items-center text-gray-600 mt-2">
					{sections.map((x, index) => {
						const value = Math.floor((index + 1) * sectionValue);
						return (
							<div
								key={index}
								className="flex flex-1 flex-col w-full justify-center content-center"
							>
								<Chip
									onClick={() => onPartSelected(index)}
									className={clsx("mx-1 max-h-2", {
										"rounded-l rounded-r-none": index === 0,
										"rounded-l-none rounded-r-none":
											index > 0 && index < PART_COUNT - 1,
										"rounded-l-none rounded-r": index === PART_COUNT - 1,
										"bg-chart-turquoiseBlue": selectedIndex >= index,
										"bg-white-500/100": selectedIndex < index,
									})}
								/>
								<p className="self-center mt-2">{value}</p>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default RedeemCoinComponent;
