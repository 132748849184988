import { t } from "@lingui/macro";
import { Tooltip } from "@mui/material";
import { useAuthState } from "@saleor/sdk";
import numbro from "numbro";
import React from "react";

import { Product, useProductPricesQuery } from "@/saleor/api";

import useChannelContext from "../shared/contexts/ChannelContext";

export interface OnSaleBadgeProps {
	productId: string;
}

const OnSaleBadge = ({ productId }: OnSaleBadgeProps) => {
	const { channel } = useChannelContext();
	const { authenticated } = useAuthState();
	const { loading, error, data } = useProductPricesQuery({
		variables: {
			id: productId,
			channel: channel,
		},
		skip: !authenticated,
		fetchPolicy: "cache-and-network",
	});

	const pricing = data?.product?.pricing;

	if (!pricing?.onSale || loading || error) {
		return <div />;
	}
	const salePercent =
		((pricing?.priceRangeUndiscounted?.start?.gross.amount || 0) -
			(pricing.priceRange?.start?.gross.amount || 0)) /
		(pricing?.priceRangeUndiscounted?.start?.gross.amount || 1);
	return (
		<div className="flex flex-row rounded-lg overflow-hidden">
			<Tooltip arrow title={t`Sale`}>
				<div className="bg-green-600 text-white px-2 py-3 font-semibold text-xs m-auto uppercase">
					{numbro(-salePercent).format({
						output: "percent",
						mantissa: 0,
					})}
				</div>
			</Tooltip>
		</div>
	);
};

export default OnSaleBadge;
