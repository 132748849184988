import { Skeleton, Stack } from "@mui/material";
import { useAuthState } from "@saleor/sdk";
import { find } from "lodash";
import { useRouter } from "next/router";
import numbro from "numbro";
import React, { useEffect, useState } from "react";

import { Product, useProductPricesQuery } from "@/saleor/api";

import useChannelContext from "../shared/contexts/ChannelContext";
import { getCurrencyPrefixForLocale } from "../utils/channel";

export interface PriceComponentProps {
	product: Product;
	size: "small" | "medium" | "large";
	selectedVariantId?: string;
}

const TEXT_SIZES = {
	small: "lg",
	medium: "2xl",
	large: "4xl",
};

const displayOption = { thousandSeparated: true, mantissa: 2, optionalMantissa: true };
const PriceComponent = ({
	product: { variants, id, metadata },
	size = "small",
	selectedVariantId,
}: PriceComponentProps) => {
	const { authenticated } = useAuthState();
	const { channel } = useChannelContext();
	const { locale } = useRouter();
	const { loading, error, data } = useProductPricesQuery({
		variables: {
			id: id,
			channel: channel,
		},
		skip: !authenticated,
		fetchPolicy: "cache-and-network",
	});
	const [priceFormat, setPriceFormat] = useState<string | undefined>(undefined);

	useEffect(() => {
		const priceDisplayFormatStr = find(metadata, {
			key: "price-format",
		});
		if (priceDisplayFormatStr?.value) {
			setPriceFormat(priceDisplayFormatStr?.value);
		} else {
			setPriceFormat(undefined);
		}
	}, [metadata]);

	if (!authenticated) {
		return null;
	}
	const textSize = TEXT_SIZES[size];

	const pricing = data?.product?.pricing;

	if (loading) {
		return (
			<Stack flexDirection="row" className="space-x-2">
				<Skeleton
					animation="pulse"
					sx={{ bgcolor: "grey.700" }}
					variant="circular"
					width={10}
					height={10}
				/>
				<Skeleton
					animation="pulse"
					sx={{ bgcolor: "grey.700" }}
					variant="circular"
					width={10}
					height={10}
				/>
				<Skeleton
					animation="pulse"
					sx={{ bgcolor: "grey.700" }}
					variant="circular"
					width={10}
					height={10}
				/>
			</Stack>
		);
	}

	if (!pricing) {
		return null;
	}

	let priceDisplay = pricing?.priceRange?.start?.gross.amount || 0;
	let undiscountedPrice = pricing?.priceRangeUndiscounted?.start?.gross.amount || 0;
	if (selectedVariantId) {
		const selectedVariant = variants?.find((v) => v?.id === selectedVariantId);
		priceDisplay = selectedVariant?.pricing?.price?.gross.amount || 0;
		undiscountedPrice = (selectedVariant?.pricing?.discount?.gross.amount || 0) + priceDisplay;
	}

	const currencyPrefix = getCurrencyPrefixForLocale(locale);
	let priceStr = `${currencyPrefix}${numbro(priceDisplay)?.format(displayOption)}`;

	if (priceFormat && priceFormat.length > 0) {
		priceStr = priceFormat.replaceAll("{{price}}", priceStr);
	}

	if (pricing?.onSale) {
		return (
			<div className={`flex flex-row space-x-2 text-${textSize}`}>
				<p className="text-gray-900 font-semibold">
					{currencyPrefix}
					{numbro(priceDisplay).format(displayOption)}
				</p>
				<p className="text-red-500 font-semibold line-through">
					{currencyPrefix}
					{numbro(undiscountedPrice).format(displayOption)}
				</p>
			</div>
		);
	}

	return <p className={`text-gray-900 font-semibold text-${textSize}`}>{priceStr}</p>;
};

export default PriceComponent;
