import { Trans } from "@lingui/macro";
import { Stack, Typography } from "@mui/material";
import numbro from "numbro";
import React from "react";

import { CheckoutDetailsFragment } from "@/saleor/api";

import RedeemCoinComponent from "./checkout/RedeemCoinComponent";

export interface PromoCodeFormData {
	promoCode: string;
}

export interface CartSummaryProps {
	checkouts: CheckoutDetailsFragment[];
	redeemCoinsVisible?: boolean;
	shippingFeeVisible: boolean;
	subTotal: number;
	shippingFee: number;
	redeemedCoinValue: number;
	discount: number;
	adminFeeVisible?: boolean;
	adminFee?: number;
	useWalletBalance?: boolean;
	walletBalance?: number;
}

export const CartSummary = ({
	checkouts,
	redeemCoinsVisible,
	shippingFeeVisible,
	subTotal,
	shippingFee,
	redeemedCoinValue,
	discount,
	adminFeeVisible = false,
	adminFee = 0,
	useWalletBalance = false,
	walletBalance = 0,
}: CartSummaryProps) => {
	const tokens = checkouts.map((c) => c.token);
	return (
		<div>
			<div className="flow-root">
				<Stack spacing={2} className="text-sm">
					<div className="text-base font-semibold">
						<Trans>Order Summary</Trans>
					</div>
					<div className="flex items-center justify-between">
						<dt className="text-gray-600 font-semibold">
							<Trans>Subtotal</Trans>
						</dt>
						<dd className="font-medium text-gray-900">
							{numbro(subTotal).format("$0,0.00")}
						</dd>
					</div>
					{shippingFeeVisible ? (
						<div className="flex items-center justify-between">
							<dt className="text-gray-600 font-semibold">
								<Trans>Shipping fee</Trans>
							</dt>
							<dd className="font-medium text-gray-900">
								{numbro(shippingFee).format("$0,0.00")}
							</dd>
						</div>
					) : (
						<div className="flex items-center justify-between">
							<dt className="text-gray-600 font-semibold">
								<Trans>Shipping Fee</Trans>
							</dt>
							<dt className="text-gray-600 font-semibold">
								<Trans>To be calculated</Trans>
							</dt>
						</div>
					)}
					<div className="flex items-center justify-between">
						<dt className="text-gray-600 font-semibold">
							<Trans>Redeemed Coins</Trans>
						</dt>
						<dd className="font-medium text-gray-900">
							- {numbro(redeemedCoinValue).format("$0,0.00")}
						</dd>
					</div>
					{discount > 0 && (
						<div className="flex items-center justify-between">
							<dt className="text-gray-600 font-semibold">
								<Trans>Discount</Trans>
							</dt>
							<dd className="font-medium text-gray-900">
								- {numbro(discount).format("$0,0.00")}
							</dd>
						</div>
					)}
					{redeemCoinsVisible && <RedeemCoinComponent tokens={tokens} />}
					{useWalletBalance && walletBalance > 0 && (
						<div className="flex items-center justify-between">
							<dt className="text-gray-600 font-semibold">
								<Trans>Pay by E-Wallet</Trans>
							</dt>
							<dd className="font-medium text-gray-900">
								- {numbro(walletBalance).format("$0,0.00")}
							</dd>
						</div>
					)}
					{adminFeeVisible && adminFee > 0 && (
						<>
							<div className="flex items-center justify-between">
								<dt className="text-gray-600 font-semibold">
									<Trans>Admin fee</Trans>
								</dt>
								<dd className="font-medium text-gray-900">
									{numbro(adminFee).format("$0,0.00")}
								</dd>
							</div>
							<div className="flex items-center justify-between font-light italic">
								<Typography variant="caption">
									<Trans>
										Spend more than $20 in a single transaction to waive off
										admin fee.
									</Trans>
								</Typography>
							</div>
						</>
					)}
					<div className="pt-4 flex items-center justify-between border-t border-gray-300">
						<dt className="text-lg font-bold text-gray-900">
							<Trans>Total</Trans>
						</dt>
						<dd className="text-lg font-bold text-gray-900">
							{numbro(
								subTotal +
									shippingFee -
									redeemedCoinValue -
									discount +
									adminFee -
									walletBalance
							).format("$0,0.00")}
						</dd>
					</div>
				</Stack>
			</div>
		</div>
	);
};
