import { Trans } from "@lingui/macro";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Link from "next/link";
import React from "react";

import Balloons from "@/icons/Balloons";
import FathersDay from "@/icons/FathersDay";
import Occasion from "@/icons/Occasion";
import OnSale from "@/icons/OnSale";
import Ranking from "@/icons/Ranking";
import Star from "@/icons/Star";
import { ProductFilterInput } from "@/saleor/api";

import { ProductCollection } from "..";

export type CollectionIcon = "featured" | "new-arrivals" | "daily-picks" | "promotion" | "balloons";

export interface CollectionBlockProps {
	name?: string;
	filter: ProductFilterInput;
	link: string;
	iconName?: CollectionIcon;
	title?: string;
}

export const CollectionBlock: React.VFC<CollectionBlockProps> = ({
	title,
	name,
	filter,
	iconName,
	link,
}) => {
	const renderIcon = (slug?: string) => {
		switch (slug) {
			case "featured":
				return <Star style={{ width: 40, height: 40 }} />;
			case "new-arrivals":
				return <Occasion style={{ width: 40, height: 40 }} />;
			case "daily-picks":
				return <Ranking style={{ width: 40, height: 40 }} />;
			case "promotion":
				return <OnSale style={{ width: 40, height: 40 }} />;
			case "balloons":
				return <Balloons style={{ width: 35, height: 35 }} />;
			case "fathers-day":
				return <FathersDay style={{ width: 35, height: 35 }} />;
			default:
				return null;
		}
	};

	return (
		<div className="pb-20">
			<div className="flex flex-row justify-between items-center mb-8">
				<div className="flex flex-row items-center">
					{renderIcon(iconName)}
					<h1 className="uppercase text-sm font-semibold text-gray-900 ml-4 pt-1">
						{name || title}
					</h1>
				</div>

				<Link href={link}>
					<a className="uppercase text-sm flex flex-row items-center">
						<ArrowForwardIosIcon style={{ fontSize: 17 }} />
						<div className="ml-2 font-semibold lg:hidden">
							<Trans>See All</Trans>
						</div>
						<div className="ml-2 font-semibold hidden lg:block">
							{<Trans>See All</Trans>}
						</div>
					</a>
				</Link>
			</div>
			<ProductCollection filter={filter} variant="block" count={4} />
		</div>
	);
};

export default CollectionBlock;
