import { Box, Button, ButtonProps, Stack } from "@mui/material";
import { useToggle } from "react-use";

import UserAddressForm from "@/components/account/address/UserAddressForm";
import GenericDialog from "@/components/shared/dialog/GenericDialog";
import { AddressDetailsFragment } from "@/saleor/api";

interface CreateUpdateAddressButtonProps {
	address?: AddressDetailsFragment;
	onCompleted?: () => void;
	variant: "create" | "update";
	buttonProps?: ButtonProps;
	title: string;
	dialogTitle?: string;
	buttonVariant?: "text" | "outlined" | "contained" | undefined;
	fullWidth?: boolean | undefined;
}

const CreateUpdateAddressButton: React.VFC<CreateUpdateAddressButtonProps> = ({
	address,
	onCompleted,
	variant,
	buttonProps,
	title,
	dialogTitle,
	buttonVariant = "outlined",
	fullWidth = true,
}) => {
	const [isDialogOpen, toggleDialog] = useToggle(false);

	const onAddCompleted = () => {
		toggleDialog();
		onCompleted && onCompleted();
	};

	return (
		<>
			<Button
				fullWidth={fullWidth}
				variant={buttonVariant}
				className="capitalize"
				{...buttonProps}
				onClick={toggleDialog}
			>
				{title}
			</Button>
			<GenericDialog
				isOpen={isDialogOpen}
				onClose={toggleDialog}
				maxWidth="md"
				title={dialogTitle}
				fullWidth
			>
				<Stack className="p-2" display="flex" flex={1}>
					<UserAddressForm
						onCompleted={onAddCompleted}
						address={address}
						variant={variant}
					/>
				</Stack>
			</GenericDialog>
		</>
	);
};

export default CreateUpdateAddressButton;
